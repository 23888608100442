import App from '@/App.vue'

export default (langCodes, localizedRoutes, { catchAll } = {}) => {
  const [defaultLang] = langCodes

  const expandRoutes = (lang, routes) => {
    const walkRoutes = catchAll && lang !== defaultLang ? [...routes, catchAll] : routes

    return walkRoutes.map(({ path, name, redirect, children = [], ...route }) => {
      const rawPath = typeof path === 'string' ? path : path[lang] || path[defaultLang]

      return {
        ...route,
        path: rawPath.replace(/^\//, ''),
        name: name ? `${name}-${lang}` : undefined,
        redirect: redirect && redirect.name ? { name: `${redirect.name}-${lang}` } : undefined,
        children: children && children.length ? expandRoutes(children) : undefined,
        meta: { lang }
      }
    })
  }

  const routes = [
    ...langCodes.map((lang, index) => ({
      path: !index ? '/' : `/${lang}`,
      component: App,
      children: expandRoutes(lang, localizedRoutes)
    }))
  ]

  if (catchAll) {
    const redirect =
      catchAll.redirect && catchAll.redirect.name ? { name: `${catchAll.redirect.name}-${defaultLang}` } : undefined

    return [...routes, { ...catchAll, redirect }]
  }

  return routes
}
