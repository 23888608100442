<template>
  <section class="layout-rooms">
    <h2 class="layout-rooms__title">{{ event.title }}</h2>

    <RouterLink
      v-for="{ slug, name, logo, thumbnail } in trackNames"
      :key="slug"
      :to="{ query: { track: slug } }"
      class="layout-rooms__card"
      @click.native="onClickRoom(tracksLiveStatus[slug])"
    >
      <figure class="layout-rooms__figure" :style="{ '--image': `url('${thumbnail}')` }">
        <img :src="logo" alt="" class="layout-rooms__logo" />
      </figure>
      <h3 class="layout-rooms__name">
        <span>{{ name }}</span>

        <div v-if="tracksLiveStatus[slug]" class="layout-rooms__live">
          <span class="layout-rooms__live-icon" />
          <p>{{ $t('common.live') }}</p>
        </div>

        <div v-else-if="tracksTimeUntilLive[slug]" class="layout-rooms__live">
          <p>{{ tracksTimeUntilLive[slug] }}</p>
        </div>
      </h3>
    </RouterLink>
  </section>
</template>

<script>
import moment from 'moment'
import _sortBy from 'lodash.sortby'

export default {
  name: 'ConferenceRooms',
  props: {
    event: { type: Object, required: true },
    tracks: { type: Object, required: true }
  },
  computed: {
    trackNames() {
      return Object.keys(this.tracks).map(slug => {
        const room = this.$api.rooms[slug] || {}
        return { ...room, slug }
      })
    },
    tracksLiveStatus() {
      if (!this.tracks) return null
      const filterCurrentDay = ({ startTime }) => moment(startTime).isSame(this.$api.liveTime, 'day')

      return Object.entries(this.tracks).reduce((statusMap, [slug, sessions]) => {
        const firstSessionStartTime = sessions
          .filter(filterCurrentDay)
          .reduce(
            (sessionStartTime, { startTime }) => moment.min([sessionStartTime, moment(startTime)]),
            moment().add(1, 'year')
          )

        const lastSessionEndTime = sessions
          .filter(filterCurrentDay)
          .reduce((sessionEndTime, { endTime }) => moment.max([sessionEndTime, moment(endTime)]), moment(0))

        return {
          ...statusMap,
          [slug]: this.$api.liveTime.isBetween(firstSessionStartTime, moment(lastSessionEndTime).add(30, 'minutes'))
        }
      }, {})
    },
    tracksTimeUntilLive() {
      return Object.entries(this.tracksLiveStatus).reduce((timeUntilMap, [key, isLive]) => {
        const nextSession = _sortBy(this.tracks[key], 'startTime').find(({ startTime }) =>
          moment(startTime).isAfter(this.$api.liveTime)
        )

        if (isLive || !nextSession) return { ...timeUntilMap, [key]: null }
        const hoursUntil = moment(nextSession.startTime).diff(this.$api.liveTime, 'hours')
        const minutesUntil = Math.floor(moment(nextSession.startTime).diff(this.$api.liveTime, 'minutes'))

        return {
          ...timeUntilMap,
          [key]:
            hoursUntil > 0
              ? `${this.$t('common.live')} ${this.$t('common.in')} ${hoursUntil} ${this.$tc(
                  'common.countdown.countdownHours',
                  hoursUntil
                )}`
              : `${this.$t('common.live')} ${this.$t('common.in')} ${minutesUntil} ${this.$tc(
                  'common.countdown.countdownMinutes',
                  minutesUntil
                )}`
        }
      }, {})
    }
  },
  methods: {
    onClickRoom(isLive) {
      if (isLive) this.$scroll.toTarget('#live-viewer', 70)
    }
  }
}
</script>
