<template>
  <article class="layout-section -split">
    <figure
      class="layout-section__figure -overlay"
      :style="{ '--image': `url('/images/events/${conference.slug}/thumbnail.jpg')` }"
    >
      <img
        :src="`/images/events/${conference.slug}/logo-overlay-${$i18n.locale}.png`"
        class="layout-section__logo"
        alt=""
      />
    </figure>

    <section class="layout-section__content">
      <h3 class="layout-section__toptitle">{{ $t('common.about') }}</h3>
      <h2 class="layout-section__title">{{ conference.title }}</h2>
      <p>{{ conference.description }}</p>
      <a href="https://forum-ameriques.org/" target="_blank" class="layout-section__cta button">{{
        $t('common.cta.learnMore')
      }}</a>
    </section>
  </article>
</template>

<script>
export default {
  name: 'ConferenceAbout',
  props: {
    conference: { type: Object, required: true }
  }
}
</script>
