<template>
  <article class="layout-section -bg-light">
    <section class="layout-section__hgroup">
      <h2 class="layout-section__title">{{ $t('conferences.title') }}</h2>
      <p>{{ $t('conferences.description') }}</p>
    </section>

    <section class="layout-grid">
      <HomeConferencesItem
        v-for="{ slug, title, year, displayDate, passed } in conferences"
        :key="title"
        v-bind="{ slug, title, year, displayDate, passed }"
      />
    </section>
  </article>
</template>

<script>
import HomeConferencesItem from './HomeConferencesItem.vue'

export default {
  name: 'HomeConferences',
  components: { HomeConferencesItem },
  computed: {
    conferences() {
      return this.$api.conferenceOrder.map(({ slug, passed }) => {
        const conference = passed ? this.$api.conferences[slug][0] : this.$api.nextEventBySlug[slug]
        return { ...conference, slug, passed, title: this.$t(`conferences.index.${slug}.title`) }
      })
    }
  }
}
</script>
