<template>
  <article class="alert">
    <figure class="alert__figure">
      <svg-icon id="action-info" />
    </figure>

    <p>{{ label }}</p>
  </article>
</template>

<script>
export default {
  name: 'ActionAlert',
  props: {
    label: { type: String, required: true }
  }
}
</script>
