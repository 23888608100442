export default {
  title: 'Foire aux questions',
  index: [
    {
      question: 'La plateforme de diffusion FEIA est-elle gratuite?',
      answer: 'Oui, le FEIA diffusera gratuitement chacune de nos six conférences annuelles tout au long de 2024.'
    },
    {
      question: 'De quoi ai-je besoin pour regarder vos émissions en direct?',
      answer:
        'IEFA TV a été optimisée pour s’adapter à un certain nombre de navigateurs et d’appareils qui accèdent à nos vidéos en direct et à notre contenu à la demande.<br/><br/> Pour visionner les diffusions en direct et les vidéos à la demande, vous devez disposer de la dernière version de votre navigateur web préféré qui prend en charge la vidéo HTML5.<br/><br/> Les navigateurs pris en charge sont Microsoft Internet Explorer à partir de la version 10, Mozilla FireFox, Google Chrome, Opera et Apple Safari, sur les ordinateurs de bureau, ainsi que Safari, Chrome et Dolphin, sur les appareils Apple tels que l’iPad et l’iPhone, et sur la plupart des appareils Android. '
    },
    {
      question:
        'Je veux visionner des vidéos sur IEFA TV, mais je ne peux pas les télécharger sur mon ordinateur. Avez-vous une URL de téléchargement?',
      answer: 'Les vidéos de la IEFA TV sont encodées pour le streaming uniquement et ne peuvent pas être téléchargées.'
    },
    {
      question: 'Existe-t-il un moyen d’écouter en direct, sans la vidéo?',
      answer: 'À l’heure actuelle, toutes nos diffusions contiennent de la vidéo et de l’audio. '
    },
    {
      question: 'Toutes les sessions sont-elles diffusées en plusieurs langues?',
      answer:
        'La conférence de Montréal et la conférence de Paris sont diffusées en français et en anglais. Le forum IEFA Latam est diffusé en anglais et en espagnol.<br/><br/> À ce stade, le World Strategic Forum, le Toronto Global Forum et le Phoenix Global Forum ne sont diffusés qu’en anglais.'
    }
  ]
}
