export default {
  title: 'Conferences',
  description: 'Watch sessions from each IEFA conference.',
  next: 'The Phoenix Global Forum will examine the international cooperation and strategic innovation needed to address emerging global challenges. Decision makers and leaders from the worlds of business, government and academia will convene to discuss these challenges and the subsequent opportunities which can ensure sustained growth and competitiveness.',
  onDemand: {
    title: 'Video on-demand',
    description: 'Watch sessions of past events.',
    noStreams: 'No streams available'
  },
  index: {
    montreal: {
      slug: 'montreal',
      title: 'Conference of Montreal',
      year: '1994',
      description:
        'The Conference of Montreal (CDM) was launched in 1994 and brings together decision makers from all spheres of society to address the most pressing issues of our time. The event is hosted by the IEFA in association with Integra Capital.',
      date: 'June 10-12, 2024',
      website: 'https://www.laconferencedemontreal.com/',
      learnMoreLink: 'https://forum-ameriques.org/conferences/montreal',
      nextEvent: {
        title: 'Leading in an Accelerating World',
        description:
          'The Age of Acceleration refers to the exponential progress of computer processing, communication technologies, globalised markets and energetic generation that characterises our time. These changes have profoundly transformed the way we govern, work, spend and interact with each other. In addition to the disruptions caused by these transformations to the conduct of global affairs, the vertiginous advance of climate change and the imperatives established in the Paris Agreement have led to a drastic shift in industrial operations and global investments. However, the rate of adoption and implementation of sustainable practices must now significantly increase if we are to meet the imperatives of our time.',
        website: 'https://www.laconferencedemontreal.com/',
        learnMoreLink: 'https://forum-ameriques.org/conferences/montreal'
      }
    },
    phoenix: {
      slug: 'phoenix',
      title: 'Phoenix Global Forum',
      year: '2024',
      description:
        'The Phoenix Global Forum is hosted by the IEFA and Phoenix Sister Cities. As the fastest growing big city in the US, Phoenix is quickly emerging as a hub for the biosciences, technology, sustainability and defense sectors, which is attracting major outside investment to the region and creating a burgeoning business ecosystem.',
      date: 'May 2, 2024',
      website: 'https://www.phoenixglobalforum.com/'
    },
    toronto: {
      slug: 'toronto',
      title: 'Toronto Global Forum',
      year: '2023',
      description:
        'The Toronto Global Forum (TGF) was launched in 2006 and is hosted by the IEFA in association with Integra Capital. The TGF gathers international thought leaders across business, government and civil society to discuss the major challenges, and subsequent opportunities, the world economy is facing today.',
      date: 'October 16-18, 2024',
      website: 'https://www.torontoglobalforum.com/',
      learnMoreLink: 'https://forum-ameriques.org/conferences/toronto',
      nextEvent: {
        title: 'Scaling for a Sustainable Future',
        description:
          'The Toronto Global Forum acts as a platform where organisations and institutions can connect with a focus on potential partnerships, investment opportunities and future collaboration. As countries and industries seek ways to enhance environmental and economic resilience and foster societal development, the conference aims to explore these topics through an international lens.',
        website: 'https://www.torontoglobalforum.com/',
        learnMoreLink: 'https://forum-ameriques.org/conferences/toronto'
      }
    },
    paris: {
      slug: 'paris',
      title: 'Conference of Paris',
      year: '2023',
      description:
        'Launched in 2017, the Conference of Paris gathers decision makers across the worlds of business, government and civil society in order to promote the exchange of ideas and perspectives surrounding the major issues of our time. The event is hosted by the IEFA in association with Integra Capital.',
      date: 'December 16-17, 2024',
      website: 'https://www.conferencedeparis.com/',
      learnMoreLink: 'https://forum-ameriques.org/conferences/paris'
    },
    miami: {
      slug: 'miami',
      title: 'World Strategic Forum',
      year: '2023',
      description:
        'The World Strategic Forum is hosted by the IEFA in association with Integra Capital and the Adam Smith Center for Economic Freedom at Florida International University (FIU). The WSF was created in 2011 and is held annually in Miami, Florida, where it welcomes attendees from all over the world. The WSF brings together decision makers from all spheres of society to tackle the most pressing issues of our time.',
      date: 'December 10-11, 2024',
      website: 'https://www.worldstrategicforum.com/',
      learnMoreLink: 'https://forum-ameriques.org/conferences/miami',
      nextEvent: {
        title: 'Thriving in the New Economy',
        description:
          'The World Strategic Forum is hosted by the IEFA in association with Integra Capital and the Adam Smith Center for Economic Freedom at Florida International University (FIU). The WSF was created in 2011 and is held annually in Miami, Florida, where it welcomes attendees from all over the world. The WSF brings together decision makers from all spheres of society to tackle the most pressing issues of our time.',
        website: 'https://www.worldstrategicforum.com/',
        learnMoreLink: 'https://forum-ameriques.org/conferences/miami'
      }
    },
    'buenos-aires': {
      slug: 'buenos-aires',
      title: 'IEFA Latam Forum',
      year: '2023',
      description:
        'The IEFA Latam Forum held its first edition in 2022, and is an annual international conference focused on the opportunities that Latin America presents within today’s global economy.',
      date: '',
      website: 'https://www.latamforum.com/',
      learnMoreLink: 'https://forum-ameriques.org/conferences/buenos-aires'
    }
  }
}
