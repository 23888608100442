<template>
  <div v-if="$bus.contentLoaded && $api.isReady" class="layout">
    <Transition name="layout__view" mode="out-in" appear @enter="onRouteEntered">
      <RouterView :key="routeKey" class="layout__view" />
    </Transition>

    <NavigationFooter />
    <NavigationTop @toggle-sidenav="isSideNavOpen = !isSideNavOpen" />
    <!-- <NavigationSide :is-open="isSideNavOpen" @close="isSideNavOpen = false" /> -->
  </div>
</template>

<script>
import moment from 'moment'
import getLocalizedRoute from './helpers/getLocalizedRoute'

export default {
  name: 'App',
  metaInfo() {
    return {
      titleTemplate: this.$t('common.meta.titleTemplate')
    }
  },
  data() {
    return {
      isSideNavOpen: false,
      popScrollPosition: null,
      zohoScript: null
    }
  },
  computed: {
    routeKey() {
      return this.$route.name.replace(/-\w\w$/, '')
    }
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler(lang) {
        this.$api.locale = lang
        moment.locale(lang)
        localStorage.setItem('feia-lang', lang)
        if (window.$zoho && window.$zoho.salesiq) window.$zoho.salesiq.reset()
      }
    },
    $route: {
      immediate: true,
      handler({ name, hash, query }, from) {
        if (from && name !== from.name) return
        if (hash) setTimeout(() => this.$scroll.toTarget(hash), 100)
        else if (!query.track) this.$scroll.to(0)
      }
    }
  },
  created() {
    this.$bus.on('pop-scroll', position => {
      this.popScrollPosition = position
    })

    if (this.$route.meta.lang !== this.$i18n.locale) {
      this.$router.replace(getLocalizedRoute(this.$i18n.locale, this.$route))
    }

    this.$watch('$route.meta.lang', (toLang, fromLang) => {
      if (!toLang || toLang === fromLang || toLang === this.$i18n.locale) return
      this.$i18n.locale = toLang
    })
  },
  // mounted() {
  // if (document.getElementById('zsiqscript')) return

  // window.$zoho = {
  //   salesiq: {
  //     widgetcode: '81d5ea3dea9cd0ec90a94c9bcfe215a7096771db847181a3d41fe8eaad9d2b0f',
  //     values: {},
  //     ready: () => window.$zoho.salesiq.language(this.$i18n.locale)
  //   }
  // }

  // const zohoScript = document.createElement('script')
  // zohoScript.type = 'text/javascript'
  // zohoScript.id = 'zsiqscript'
  // zohoScript.defer = true
  // zohoScript.src = 'https://salesiq.zoho.com/widget'
  // this.zohoScript = document.body.appendChild(zohoScript)
  // },
  methods: {
    onRouteEntered() {
      if (this.popScrollPosition) {
        this.$scroll.to(this.popScrollPosition)
        this.popScrollPosition = null
      } else if (this.$route.hash) {
        this.$scroll.toTarget(this.$route.hash)
      } else {
        this.$scroll.to(0)
      }
    }
  }
}
</script>
