<template>
  <header v-if="!isEventPage" class="home-header__menu">
    <LocaleLink v-appear:right="{ delay: 300 }" :to="{ name: 'home' }" class="navigation-top__logo">
      <img :src="$t('common.logo')" alt="FEIA" />
    </LocaleLink>
  </header>
</template>

<script>
// import { supportedLocales } from '@/locales'
import getLocalizedRoute from '@/helpers/getLocalizedRoute'

export default {
  name: 'NavigationHeader',
  computed: {
    langLinks() {
      // return supportedLocales.filter(lang => lang !== this.$i18n.locale)
      return ['en', 'fr'].filter(lang => lang !== this.$i18n.locale)
    },
    isEventPage() {
      const events = ['toronto', 'miami', 'paris', 'buenos-aires', 'phoenix', 'montreal']
      return events.some(name => this.$route.name.includes(name))
    }
  },
  methods: {
    getLangLink(lang) {
      const link = getLocalizedRoute(lang, this.$route)
      return link
    }
  }
}
</script>
