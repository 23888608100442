<template>
  <section class="viewer-panel">
    <h3 class="viewer-panel__title">{{ $t('common.viewer.overview') }}</h3>

    <div class="viewer-panel__content">
      <ScrollBox class="viewer-panel__list" content-tag="ul">
        <li
          v-for="(session, index) in sessions"
          :key="index"
          class="viewer-panel__item"
          :class="{ '-active': currentSessionIndex === index }"
        >
          <h4 class="viewer-panel__heading">{{ session.title }}</h4>
          <p class="viewer-panel__time">{{ formatTime(session) }}</p>
        </li>
      </ScrollBox>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import { ScrollBox } from '@/components/common'

export default {
  name: 'ConferenceLivePanel',
  components: { ScrollBox },
  props: {
    sessions: { type: Array, default: Array },
    currentSessionIndex: { type: Number, default: -1 }
  },
  methods: {
    formatTime({ startTime }) {
      return moment(startTime).format('h:mm A')
    }
  }
}
</script>
