<template>
  <DynamicElement
    :is="tag"
    class="layout-dropdown"
    :class="{ '-open': isOpen }"
    :style="style"
    @transitionend="onTransitionEnd"
  >
    <DynamicElement :is="contentTag" ref="content" :class="className">
      <slot />
    </DynamicElement>
  </DynamicElement>
</template>

<script>
export default {
  name: 'DropDown',
  props: {
    isOpen: { type: Boolean, required: true },
    tag: { type: String, default: 'div' },
    contentTag: { type: String, default: 'div' },
    contentClass: { type: [String, Array, Object], default: Array }
  },
  data() {
    return { height: this.isOpen ? 'auto' : 0 }
  },
  computed: {
    className() {
      return ['layout-dropdown__content', { '-open': this.isOpen }, this.contentClass]
    },
    style() {
      return {
        '--height': !Number(this.height) ? this.height : `${this.height}px`
      }
    }
  },
  watch: {
    isOpen(isOpen, wasOpen) {
      if (isOpen === wasOpen) return
      if (wasOpen) this.height = this.$refs.content.clientHeight
      else this.resetAfterTransition()

      this.$nextTick(() => {
        setTimeout(() => {
          this.height = this.isOpen ? this.$refs.content.clientHeight : 0
        }, 10)
      })
    }
  },
  methods: {
    resetAfterTransition() {
      this.$once('transition-end', () => {
        if (this.isOpen) this.height = 'auto'
      })
    },
    onTransitionEnd() {
      this.$emit('transition-end')
    }
  }
}
</script>

<style lang="scss">
$ease: cubic-bezier(0.075, 0.82, 0.165, 1);

.layout-dropdown {
  height: var(--height, auto);
  overflow: hidden;
  backface-visibility: hidden;
  transition: height 225ms $ease;

  &.-open {
    transition: height 350ms $ease;
  }

  &__content {
    margin-top: 0;
    margin-bottom: 0;
    opacity: 0;
    transform: translate3d(0, -1.25rem, 0);
    pointer-events: none;
    transition: transform 200ms $ease, opacity 75ms linear;

    &::before,
    &::after {
      display: table;
      content: '';
    }

    &.-open {
      opacity: 1;
      transform: translateZ(0);
      pointer-events: auto;
      z-index: 0;
      transition: transform 350ms $ease, opacity 200ms linear 75ms;
    }
  }
}
</style>
