<template>
  <button class="button">
    <slot />
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'ActionButton',
  props: {
    label: { type: String, default: '' }
  }
}
</script>
