<template>
  <article class="page-header" :style="{ '--image': `url('/images/events/${conference.slug}/background-small.png')` }">
    <h6 v-appear class="page-header__toptitle">{{ $t('common.since') }} {{ conference.year }}</h6>
    <h1 v-appear="{ delay: 100 }" class="page-header__title">{{ conference.title }}</h1>
  </article>
</template>

<script>
export default {
  name: 'ConferenceHeader',
  props: {
    conference: { type: Object, required: true }
  }
}
</script>
