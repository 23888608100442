<template>
  <header
    class="layout-section -split -bg-cover"
    :style="{ '--image': `url('/images/events/${nextConferenceSlug}/background.png')` }"
  >
    <section class="layout-section__content">
      <LocaleLink
        :to="{ name: 'conference', params: { slug: nextConferenceSlug } }"
        tag="h2"
        class="page-header__toptitle -bigger -link"
      >
        {{ $t(`conferences.index.${nextConferenceSlug}.title`) }}
      </LocaleLink>

      <h1 class="layout-section__title -bigger">{{ $t(`conferences.index.${nextConferenceSlug}.nextEvent.title`) }}</h1>
      <p>{{ $t(`conferences.index.${nextConferenceSlug}.nextEvent.description`) }}</p>

      <LocaleLink
        :to="{ name: 'conference', params: { slug: nextConferenceSlug } }"
        class="button -light -icon layout-section__cta"
      >
        {{ $t('common.cta.watchNow') }}
        <svg-icon id="video-arrow" />
      </LocaleLink>
    </section>

    <div class="layout-section__figure -reverse -video -live" :style="{ '--image': `url('/images/about.png')` }">
      <figure class="video -fit">
        <!-- <iframe
          :src="embedUrl"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          class="video__player"
          allowfullscreen
        /> -->

        <div class="video__live">
          <span class="video__live-icon" />
          <p>{{ $t('common.live') }}</p>
        </div>

        <SvgIcon id="video-play" class="video__play" />

        <LocaleLink :to="{ name: 'conference', params: { slug: nextConferenceSlug } }" class="video__link" />
      </figure>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HomeLive',
  props: {
    event: { type: Object, required: true }
  },
  computed: {
    // embedUrl() {
    //   const [session] = this.event.sessions
    //   const eventId = Object.values(session.eventId).find(Boolean)
    //   return `https://vimeo.com/event/${eventId}/embed`
    // },
    nextConferenceSlug() {
      return this.$api.conferenceOrder[0].slug
    }
  }
}
</script>
