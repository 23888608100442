<template>
  <LocaleLink :to="{ name: 'conference', params: { slug } }" class="grid__card -row">
    <figure class="grid__figure -small -overlay" :style="{ '--image': `url('/images/events/${slug}/thumbnail.jpg')` }">
      <img :src="`/images/events/${slug}/logo-overlay-${$i18n.locale}.png`" class="grid__logo" alt="" />
    </figure>

    <section class="grid__content -row">
      <div class="grid__hgroup">
        <h3 class="grid__title -bolder">{{ title }}</h3>
        <h6 v-if="isLive" class="grid__label">{{ $t('common.live') }}</h6>
        <h6 v-else-if="!passed" class="grid__label">{{ displayDate }}</h6>
      </div>

      <div class="grid__arrow">
        <svg-icon id="arrow" />
      </div>
    </section>
  </LocaleLink>
</template>

<script>
export default {
  name: 'HomeConferencesItem',
  props: {
    slug: { type: String, required: true },
    title: { type: String, required: true },
    displayDate: { type: String, required: true },
    year: { type: String, required: true },
    passed: { type: Boolean, required: true }
  },
  computed: {
    isLive() {
      return this.$api.liveEvent && this.$api.liveEvent.slug === this.slug
    }
  }
}
</script>
