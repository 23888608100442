import Vue from 'vue'
import VueRouter from 'vue-router'
import { eventBus } from './plugins/eventBus'
import createLocalizedRoutes from './helpers/createLocalizedRoutes'
import { Home, Terms, Privacy, Conference, Faq, Streaming, Contact } from '@/views'

Vue.use(VueRouter)

const routes = createLocalizedRoutes(
  ['en', 'fr', 'es'],
  [
    {
      path: '',
      name: 'home',
      component: Home
    },
    {
      path: { en: 'conferences/:slug', fr: 'conferences/:slug', es: 'conferencias/:slug' },
      name: 'conference',
      component: Conference,
      props: true
    },
    {
      path: {
        en: 'conferences/:slug/:year',
        fr: 'conferences/:slug/:year',
        es: 'conferencias/:slug/:year'
      },
      name: 'streaming',
      component: Streaming,
      props: true
    },
    {
      path: { en: 'faq', fr: 'faq', es: 'faq' },
      name: 'faq',
      component: Faq
    },
    {
      path: { en: 'contact', fr: 'contact', es: 'contact' },
      name: 'contact',
      component: Contact
    },
    {
      path: { en: 'terms-of-use', fr: 'conditions-utilisation', es: 'terminos-uso' },
      name: 'terms',
      component: Terms
    },
    {
      path: { en: 'privacy-policy', fr: 'politique-confidentialite', es: 'politica-privacidad' },
      name: 'privacy',
      component: Privacy
    }
  ],
  {
    catchAll: {
      path: '*',
      redirect: { name: 'home' }
    }
  }
)

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: '-active',
  linkExactActiveClass: '-exact-active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return eventBus.emit('pop-scroll', savedPosition.y)
  }
})

export default router
