<template>
  <article
    class="layout-section -bg-cover"
    :style="{ '--image': `url('/images/events/${nextConferenceSlug}/background.png')` }"
  >
    <section class="layout-section__content">
      <LocaleLink
        :to="{ name: 'conference', params: { slug: nextConferenceSlug } }"
        tag="h2"
        class="page-header__toptitle -bigger -link"
      >
        {{ $t(`conferences.index.${nextConferenceSlug}.title`) }}
      </LocaleLink>

      <h1 class="layout-section__title -bigger">{{ $t(`conferences.index.${nextConferenceSlug}.nextEvent.title`) }}</h1>
      <p>{{ $t(`conferences.index.${nextConferenceSlug}.nextEvent.description`) }}</p>

      <div class="layout-section__cta">
        <LocaleLink :to="{ name: 'conference', params: { slug: nextConferenceSlug } }" class="button -light">{{
          $t('common.cta.learnMore')
        }}</LocaleLink>

        <button class="button -disabled-light">
          {{ $t('common.cta.watchLive') }} {{ $t(`conferences.index.${nextConferenceSlug}.date`) }}
        </button>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: 'HomeHeader',
  computed: {
    nextConferenceSlug() {
      return this.$api.conferenceOrder[0].slug
    }
  }
}
</script>
