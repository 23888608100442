<template>
  <article
    class="layout-section -split-alt"
    :style="{ '--image': `url('/images/events/${slug}/background-small.png')` }"
  >
    <h2 class="layout-section__title">{{ conference.title }}</h2>
    <a :href="conference.learnMoreLink" target="_blank" rel="noopener" class="button -light">{{
      $t('common.cta.learnMore')
    }}</a>
  </article>
</template>

<script>
export default {
  name: 'StreamingAbout',
  props: {
    slug: { type: String, required: true },
    year: { type: String, required: true },
    conference: { type: Object, required: true },
    event: { type: Object, required: true }
  }
}
</script>
