export default {
  meta: {
    titleTemplate: 'IEFA TV'
  },
  about: 'Acerca de',
  live: 'En vivo',
  room: 'Sala',
  floor: 'Idioma original',
  intro: {
    text: 'Te damos la bienvenida a la plataforma de streaming del Foro Económico Internacional de las Américas (IEFA).',
    text2:
      'Nos complace anunciar nuestro próximo evento, el Phoenix Global Forum, el cual se celebrará y transmitirá en vivo desde la ciudad de Phoenix, Arizona el 2 de mayo de 2024, desde las 08:30 hasta las 18:00 hora local (MST) por IEFA.TV. Para acceder al programa completo y ver la lista de ponentes confirmados, te invitamos a visitar el sitio web oficial del Phoenix Global Forum.',
    countdownDays: 'Día | Días',
    countdownHours: 'Hora | Horas',
    countdownMinutes: 'Minuto | Minutos',
    countdownSeconds: 'Segundo | Segundos'
  },
  video: {
    originalLanguage: 'Idioma original',
    title: 'Cooperación para un Mundo Sostenible',
    text: 'Nos complace anunciar nuestro próximo evento, el IEFA Latam Forum, el cual se celebrará y transmitirá en vivo desde la ciudad de Buenos Aires el martes, 26 de marzo de 2024, desde las 08:45 hasta las 18:30 hora local (ART) por IEFA.TV. Para acceder al programa completo y ver la lista de ponentes confirmados, te invitamos a visitar el sitio web oficial del IEFA Latam Forum.'
  },
  viewer: {
    publication: 'This video was published in',
    learnMore: 'To learn more about the event visit the',
    website: 'event website',
    overview: 'Conference Overview',
    about: 'About the event',
    program: 'Program',
    speakers: 'Speakers',
    partners: 'Partners'
  },
  brand: 'Foro Económico Internacional de las Américas',
  brandSmall: 'IEFA',
  navigation: {
    home: 'Inicio',
    events: 'Eventos',
    conferences: 'Conferencias',
    archives: 'Archivos',
    about: 'Acerca de',
    aboutFEIA: 'Acerca del FEIA',
    contact: 'Contáctenos',
    faq: 'Ayuda / FAQ',
    terms: 'Términos de uso',
    privacy: 'Política de privacidad'
  },
  cta: {
    learnMore: 'Más información',
    contact: 'Contáctenos',
    tickets: 'Registro',
    registerNow: 'Regístrese ahora',
    watchLive: 'Ver en vivo el',
    watchNow: 'Ver en vivo'
  },
  city: {
    montreal: 'Montreal',
    toronto: 'Toronto',
    paris: 'París',
    miami: 'Miami',
    buenosAires: 'Buenos Aires',
    phoenix: 'Phoenix'
  },
  form: {
    name: 'Nombre',
    email: 'Correo electrónico',
    company: 'Empresa',
    phone: 'Número de teléfono',
    subject: 'Asunto',
    getTouch: 'Ponerse en contacto',
    partner: 'Convertirse en socio',
    message: 'Mensaje',
    button: 'Enviar',
    tollFree: 'Número gratuito'
  },
  countdown: {
    countdownDays: 'día | días',
    countdownHours: 'hora | horas',
    countdownMinutes: 'minuto | minutos',
    countdownSeconds: 'segundo | segundos'
  },
  copyright: 'Foro Económico Internacional de las Américas',
  cretedBy: 'Sitio web creado por',
  languages: {
    title: 'Languages',
    french: 'Français',
    english: 'English',
    spanish: 'Español'
  },
  logo: '/images/brand/logo-en-white.png',
  logoSmall: '/images/brand/logo-small-en-white.png',
  previousConferences: 'Conferencias anteriores',
  since: 'Desde',
  watchLive: 'Ver en vivo',
  nextEvent: 'Próximo evento',
  in: 'En'
}
