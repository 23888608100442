import sortBy from 'lodash.sortby'
import moment from 'moment'
// import * as conferenceData from './data'
import { toKebabCase } from '@/helpers/strings'

const isObject = v => typeof v === 'object' && !Array.isArray(v) && v !== null

const toLocaleWithFallbackFactory =
  locale =>
  (data = null) => {
    if (!isObject(data)) return data
    return data[locale] || Object.values(data).find(Boolean) || ''
  }

const formatDate = (dateString, timezoneOffset = -4) => {
  if (!dateString) return moment().subtract(5, 'years').toISOString()
  const tzString = `${timezoneOffset < 0 ? '-' : '+'}${String(Math.abs(timezoneOffset)).padStart(2, 0)}`
  return moment(`${dateString.replace(/\s/, 'T')}${tzString}:00`).toISOString()
}

export default Vue => {
  const WpApiVm = new Vue({
    name: 'WpApiVm',
    data() {
      return {
        conferenceData: {},
        locale: 'en',
        liveTime: moment(),
        isReady: false
      }
    },
    computed: {
      conferences() {
        const toLocaleWithFallback = toLocaleWithFallbackFactory(this.locale)

        return Object.entries(this.conferenceData).reduce((conferences, [slug, events]) => {
          const localizedEventData = events
            .filter(({ hidden }) => !hidden)
            .map(({ sessions, timezone, startDate, endDate, ...data }) => {
              const safeSessions = Array.isArray(sessions) ? sessions : []

              return {
                slug,
                startDate: formatDate(startDate, timezone),
                endDate: formatDate(endDate, timezone),
                sessions: safeSessions.map(({ title, description, startTime, endTime, room, ...session }) => ({
                  ...session,
                  room: room ? room.slug : null,
                  startTime: formatDate(startTime, timezone),
                  endTime: formatDate(endTime, timezone),
                  title: toLocaleWithFallback(title) || '',
                  description: toLocaleWithFallback(description) || ''
                })),
                ...Object.entries(data).reduce(
                  (localizedData, [key, value]) => ({
                    ...localizedData,
                    [key]: toLocaleWithFallback(value)
                  }),
                  {}
                )
              }
            })

          return { ...conferences, [toKebabCase(slug)]: sortBy(localizedEventData, 'startDate').reverse() }
        }, {})
      },
      conferenceOrder() {
        const conferenceDates = Object.entries(this.nextEventBySlug).map(([slug, nextEvent]) => {
          const event = nextEvent || this.conferences[slug][0]

          return {
            date: event.startDate,
            endDate: event.endDate,
            passed: !nextEvent,
            slug
          }
        })

        const [upcomingConferences, passedConferences] = sortBy(conferenceDates, 'date').reduce(
          ([upcoming, passed], conference) => {
            return conference.passed
              ? [[...upcoming], [...passed, conference]]
              : [[...upcoming, conference], [...passed]]
          },
          [[], []]
        )

        return [...upcomingConferences, ...passedConferences]
      },
      liveEvent() {
        const liveEvent = Object.values(this.nextEventBySlug).find(
          event => event && this.liveTime.isBetween(event.startDate, event.endDate)
        )

        return liveEvent ? this.conferences[liveEvent.slug][0] : null
      },
      nextEventBySlug() {
        return Object.entries(this.conferences).reduce((nextEventMap, [slug, conference]) => {
          const nextEvent = [...conference].reverse().find(({ endDate }) => this.liveTime.isBefore(endDate))
          return { ...nextEventMap, [slug]: nextEvent || null }
        }, {})
      },
      rooms() {
        return Object.values(this.conferenceData).reduce((rooms, conference) => {
          conference.forEach(({ sessions }) => {
            if (!sessions) return

            sessions.forEach(({ room }) => {
              if (!room) return

              rooms[room.slug] = {
                name: room.name,
                thumbnail: `/images/rooms/${room.slug}.png`,
                logo: `/images/rooms/${room.slug}-logo.png`
              }
            })
          })

          return rooms
        }, {})
      }
    },
    watch: {
      conferences: {
        immediate: true,
        handler() {
          // console.log('Live Event:', this.liveEvent)
          // console.log('Rooms:', this.rooms)
          // console.log('conferences:', this.conferences)
          // console.log('conferenceOrder:', this.conferenceOrder)
          // console.log('nextEventBySlug:', this.nextEventBySlug)
        }
      }
    },
    created() {
      this.updateLiveTime()
      this.fetchData()
    },
    methods: {
      fetchData() {
        fetch('https://api.forum-americas.org/?rest_route=/wcd/v1/feia-tv')
          .then(res => res.json())
          .then(data => {
            this.conferenceData = data
            if (!this.isReady) this.isReady = true
            setTimeout(this.fetchData, 60000)
          })
      },
      updateLiveTime() {
        this.liveTime = moment()
        setTimeout(this.updateLiveTime, moment().endOf('second').diff(moment(), 'ms'))
      }
    }
  })

  Vue.prototype.$api = WpApiVm
}
