export default {
  title: 'Preguntas frecuentes',
  index: [
    {
      question: '¿Es gratuita la plataforma de streaming de la IEFA?',
      answer: 'Sí, la IEFA retransmitirá gratuitamente cada una de las seis conferencias anuales a lo largo del 2024.'
    },
    {
      question: '¿Qué necesito para ver sus retransmisiones en directo?',
      answer:
        'IEFA TV se ha optimizado para adaptarse a una serie de navegadores y dispositivos que acceden a nuestros vídeos en directo y contenidos a la carta.<br/><br/> Para ver las retransmisiones en directo y los vídeos a la carta, debe disponer de la última versión de su navegador web preferido que admita vídeo HTML5.<br/><br/> Los navegadores compatibles son Microsoft Internet Explorer a partir de la versión 10, Mozilla FireFox, Google Chrome, Opera y Apple Safari, en computadoras, así como Safari, Chrome y Dolphin, en dispositivos Apple como iPad y iPhone, y en la mayoría de dispositivos Android.'
    },
    {
      question:
        'Puedo ver vídeos en IEFA TV, pero no puedo descargarlos en mi computadora. Tienen una URL de descarga?',
      answer:
        'Los vídeos de IEFA TV están codificados únicamente para su transmisión en streaming y actualmente no se pueden descargar.'
    },
    {
      question: '¿Hay alguna forma de escuchar en directo, sin el vídeo?',
      answer: 'En este momento, todas nuestras transmisiones contienen vídeo y audio.'
    },
    {
      question: '¿Se retransmiten todas las sesiones en varios idiomas?',
      answer:
        'La Conferencia de Montreal y la Conferencia de París se retransmiten en francés e inglés. El Foro IEFA Latam se retransmiten en inglés y español.<br/><br/> Por el momento, el World Strategic Forum, el Toronto Global Forum y el Phoenix Global Forum sólo se retransmiten en inglés.'
    }
  ]
}
