import './sass/app.scss'
import './startup'

import Vue from 'vue'
import VueMeta from 'vue-meta'
import DeviceQueries from 'vue-device-queries'
import router from './router'
import i18n from './locales'
import { GlobalComponents } from './components'
import globalMixins from './plugins/globalMixins'
import appearDirective from './plugins/appearDirective'
import ScrollUtils from './plugins/ScrollUtils'
import EventBus from './plugins/eventBus'
import wpApi from './plugins/wpApi'

Vue.config.productionTip = false

Vue.use(globalMixins)
Vue.use(appearDirective)
Vue.use(ScrollUtils, { scrollEventTarget: window })
Vue.use(GlobalComponents)
Vue.use(DeviceQueries, { laptop: `min-width: 1200px`, tablet: `min-width: 765px`, tabletH: `min-width: 950px` })
Vue.use(VueMeta)
Vue.use(EventBus)
Vue.use(wpApi)

new Vue({
  render: h => h('RouterView'),
  router,
  i18n
}).$mount('#app')
