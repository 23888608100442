export default {
  title: 'Conférences',
  description: 'Visionnez les sessions de chacune des conférences du FEIA.',
  next: "La 30ème édition de la Conférence de Montréal cherchera à aborder des questions stratégiques et existentielles clés concernant : la transition énergétique, l'avenir du travail, l'innovation et la perturbation économique, la sécurité de la chaîne d'approvisionnement, la géopolitique et la santé mondiale, avec l'objectif particulier d'offrir aux individus et aux organisations une capacité d'agir dans un contexte dans lequel dominent des deux plus importants catalyseurs de notre temps, l'intelligence artificielle et le changement climatique.",
  onDemand: {
    title: 'Vidéo sur demande',
    description: 'Regardez les sessions des événements passés.',
    noStreams: 'No streams available'
  },
  index: {
    montreal: {
      slug: 'montreal',
      title: 'Conférence de Montréal',
      year: '1994',
      description:
        'La Conférence de Montréal (CDM) a été lancée par le FEIA en 1994 et réunit des décideurs de toutes les sphères de la société pour aborder les plus grands enjeux actuels.',
      date: '10-12 juin 2024',
      website: 'https://www.laconferencedemontreal.com/',
      learnMoreLink: 'https://forum-ameriques.org/fr/conferences/montreal',
      nextEvent: {
        title: 'MENER DANS UN MONDE EN ACCÉLÉRATION',
        description: `L'ère de l'accélération fait référence à la progression exponentielle du traitement informatique, des technologies de la communication, des marchés mondialisés et de la production d'énergie qui caractérise notre époque. Ces changements, qui se sont produits en moins de deux décennies, ont profondément transformé la façon dont nous gouvernons, travaillons, consommons et interagissons les uns avec les autres. Outre les bouleversements engendrés par ces transformations sur la conduite des affaires mondiales, l'avancée vertigineuse du changement climatique et les impératifs établis dans l'Accord de Paris ont entraîné un changement radical des opérations industrielles et des investissements mondiaux.`,
        website: 'https://www.laconferencedemontreal.com/',
        learnMoreLink: 'https://forum-americas.org/fr/conferences/montreal'
      }
    },
    phoenix: {
      slug: 'phoenix',
      title: 'Phoenix Global Forum',
      year: '2024',
      description:
        "Le Phoenix Global Forum est organisé par le FEIA en association avec Phoenix Sister Cities. En tant que grande ville à la croissance la plus rapide des États-Unis depuis cinq ans, la région émerge rapidement comme une plaque tournante pour les organisations des secteurs des biosciences, de la technologie, de la durabilité et de la défense ; attirant d'importants investissements extérieurs dans la région et créant un écosystème d'entreprises prometteur.",
      date: '2 mai 2024',
      website: 'https://www.phoenixglobalforum.com/',
      learnMoreLink: 'https://forum-ameriques.org/fr/conferences/phoenix'
    },
    toronto: {
      slug: 'toronto',
      title: 'Toronto Global Forum',
      year: '2023',
      description:
        "Le Toronto Global Forum est organisé par le FEIA en association avec Integra Capital. L'événement a été lancé en 2006 et rassemble des leaders d’opinion internationaux issus du monde des affaires, du gouvernement et de la société civile pour discuter des principaux défis, et des opportunités qui en découlent, auxquels l’économie mondiale est actuellement confrontée.",
      date: '16-18 octobre 2024',
      website: 'https://www.torontoglobalforum.com/',
      learnMoreLink: 'https://forum-ameriques.org/fr/conferences/toronto',
      nextEvent: {
        title: 'Scaling for a Sustainable Future',
        description:
          'Le Toronto Global Forum sert de plateforme où les organisations et les institutions peuvent se connecter en mettant l’accent sur les partenariats potentiels, les opportunités d’investissement et la collaboration future. Alors que les pays et les industries cherchent des moyens d’améliorer la résilience environnementale et économique et de favoriser le développement sociétal, la conférence vise à explorer ces sujets à travers une perspective internationale.',
        website: 'https://www.torontoglobalforum.com/',
        learnMoreLink: 'https://forum-ameriques.org/conferences/toronto'
      }
    },
    paris: {
      slug: 'paris',
      title: 'Conférence de Paris',
      year: '2023',
      description:
        "Lancée en 2017, la Conférence de Paris rassemble des décideurs du monde des affaires, des gouvernements et de la société civile afin de promouvoir l’échange d’idées et de perspectives autour des grands enjeux actuels. L'événement est organisé par le FEIA et Integra Capital.",
      date: '16-17 décembre 2024',
      website: 'https://www.conferencedeparis.com/',
      learnMoreLink: 'https://forum-ameriques.org/fr/conferences/paris'
    },
    miami: {
      slug: 'miami',
      title: 'World Strategic Forum',
      year: '2023',
      description:
        "Le World Strategic Forum est organisé par le FEIA en association avec Integra Capital et l'Adam Smith Center for Economic Freedom à Florida International University (FIU). Le WSF a été créé en 2011 et se tient chaque année à Miami, accueillant des participants du monde entier. Le WSF rassemble des décideurs de toutes les sphères de la société pour discuter des problèmes les plus urgents de notre époque.",
      date: '10-11 décembre 2024',
      website: 'https://www.worldstrategicforum.com/',
      learnMoreLink: 'https://forum-ameriques.org/fr/conferences/miami',
      nextEvent: {
        title: 'Thriving in the New Economy',
        description:
          'The World Strategic Forum is hosted by the IEFA in association with Integra Capital and the Adam Smith Center for Economic Freedom at Florida International University (FIU). The WSF was created in 2011 and is held annually in Miami, Florida, where it welcomes attendees from all over the world. The WSF brings together decision makers from all spheres of society to tackle the most pressing issues of our time.',
        website: 'https://www.worldstrategicforum.com/',
        learnMoreLink: 'https://forum-ameriques.org/conferences/miami'
      }
    },
    'buenos-aires': {
      slug: 'buenos-aires',
      title: 'IEFA Latam Forum',
      year: '2023',
      description:
        'L’IEFA Latam Forum a tenu sa première édition en 2022. Il s’agit d’une conférence internationale annuelle axée sur le rôle et les opportunités que présente l’Amérique latine dans l’économie mondiale d’aujourd’hui.',
      date: '',
      website: 'https://www.latamforum.com/',
      learnMoreLink: 'https://forum-ameriques.org/fr/conferences/buenos-aires'
    }
  }
}
