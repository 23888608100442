export default {
  title: 'PROTECTION DES RENSEIGNEMENTS PERSONNELS',
  update: 'Dernière mise à jour effectuée le 15 mai 2023.',
  meta: {
    title: 'Politique de confidentialité'
  },
  wp: 'false',
  content_static: [
    { type: 'title', text: 'INTRODUCTION' },
    `Face aux nouveaux enjeux et dangers relatifs à la préservation de la sécurité des renseignements personnels découlant des affaires en ligne, il est nécessaire de porter une attention particulière à la protection de la vie privée. C’est pourquoi le Forum économique international des Amériques (ci-après « FEIA ») se préoccupe sérieusement du caractère privé de vos renseignements personnels et nous nous engageons à respecter la confidentialité des informations que nous collectons. `,
    `FEIA s’engage à respecter, conformément la présente politique de protection des renseignements personnels (ci-après la « <strong>Politique</strong> ») et les lois applicables et en vigueur au Canada et au Québec, les obligations concernant la collecte et le traitement de vos informations personnelles et confidentielles obtenues dans le cadre de l’exploitation de son entreprise, afin que chacun puisse bénéficier du respect de sa vie privée.`,
    `Dans ce cadre, FEIA souhaite s’assurer de l’exactitude, de la sécurité et de la confidentialité de vos renseignements personnels, et à ne pas recueillir, utiliser ou communiquer vos renseignements personnels autrement qu’en conformité avec cette Politique et ces lois applicables.`,
    `La Politique décrit les renseignements que FEIA peut recueillir dans le cadre de ses activités, auprès de ses Utilisateurs.`,
    `La Politique décrit aussi, entre autres, la façon dont FEIA collecte, traite, conserve vos informations, les fins auxquelles elles sont recueillies, l’utilisation qui en est faite et les circonstances dans lesquelles elles peuvent être transmises à des tiers, le cas échéant. La Politique vous explique vos droits en matière de collecte, d’utilisation et de communication de vos informations personnelles. `,
    { type: 'title', text: 'PORTÉE DE LA POLITIQUE ' },
    `Nous récoltons vos renseignements personnels dans le cadre de notre mission, qui consiste à organiser des événements au cours desquels des chefs d’État et de gouvernement, des membres du secteur privé et de la société civile, des dirigeants syndicaux et académiques afin de discuter des grands enjeux économiques internationaux. Nous mettons à votre disposition sur notre Plateforme l’information et les contenus relatifs aux événements.`,
    `Lorsque l’Utilisateur navigue sur la Plateforme ou s’abonne à nos publications par courriel, il accepte les modalités et conditions de la présente Politique. `,
    `Vous acceptez que vos renseignements personnels recueillis lors de remplissage de tout formulaire en ligne afin, notamment, de prendre contact, de télécharger du contenu sur la Plateforme, ou de rendre d’autres services liés aux produits, soient transmis au personnel concerné et autorisé du FEIA. Cette dernière ne communiquera qu’aux tiers dont la participation est strictement nécessaire à la livraison des Produits et/ou à l’entretien de notre relation commerciale avec vous, le cas échéant, que les renseignements nécessaires à ces fins (les tiers susmentionnés pouvant être ci-après collectivement désignés les « <strong>Prestataires tiers</strong> »).`,
    `En fournissant à FEIA vos renseignements personnels, vous l’autorisez à les utiliser et à les communiquer aux Prestataires tiers, le cas échéant, dans le cadre de la livraison des produits, de la gestion de notre relation contractuelle, de notre relation commerciale avec vous, et cela conformément à la présente Politique et tel qu’autorisé ou requis par la loi, ainsi qu’implicitement, si cela est indiqué par les circonstances.`,
    `Par ailleurs, FEIA sollicitera votre autorisation avant d’utiliser vos renseignements personnels à des fins autres que celles visées par votre consentement initial donné en vertu de la Politique.`,
    `Enfin, vous pouvez retirer votre consentement à tout moment, sous réserve de restrictions juridiques et contractuelles et d’un préavis raisonnable. Sachez cependant que sans votre consentement, FEIA pourrait ne plus être en mesure de vous fournir ou de pouvoir continuer à vous fournir certains des services ou renseignements qui pourraient vous être utiles.`,
    `Si vous fournissez à FEIA ou à ses Prestataires tiers des renseignements personnels concernant une autre personne physique ou morale, vous vous engagez à détenir l’autorisation nécessaire pour le faire et/ou que vous avez obtenu tous les consentements nécessaires de la part de ce tiers afin de nous permettre de recueillir, d’utiliser et de communiquer ses renseignements personnels aux fins établies dans la présente Politique.`,
    `Pour retirer votre consentement ou ne plus recevoir de communication électronique de notre part, veuillez écrire à l’adresse courriel suivante : admin@forum-americas.org.`,
    { type: 'title', text: 'COLLECTE DES RENSEIGNEMENTS PERSONNELS' },
    `Un renseignement personnel est une information associée spécifiquement à une personne physique et qui peut servir à l’identifier, soit en tant que telle, soit en l’agençant avec d’autres renseignements détenus par FEIA pourrait avoir accès. Une information rendue anonyme ou ayant été agencée pour ne plus pouvoir être utilisée pour identifier un individu en particulier ne constitue pas un renseignement personnel. Par exemple, l’adresse postale du bureau d’un individu n’est pas un renseignement personnel en soi, sauf lorsqu’il est mentionné avec un autre renseignement à son sujet, ou lorsque sa seule mention révélerait un renseignement personnel le concernant.`,
    `FEIA peut obtenir auprès de l’Utilisateur des renseignements personnels lorsqu’il sauvegarde des renseignements personnels sur la Plateforme ou, le cas échéant, en transmettant autrement des renseignements personnels, notamment en créant un compte d’Utilisateur et en choisissant un mot de passe, en ouvrant une session en tant qu’Utilisateur inscrit à la Plateforme, en lançant une recherche de produit, en demandant un service, en fournissant des renseignements dans son compte, en communiquant avec nous par téléphone, par courriel ou autrement, ou encore en remplissant un formulaire pour participer à un concours, à une demande d’inventaire, à une promotion ou à un sondage. En remplissant un tel formulaire, vous nous autorisez à recueillir vos renseignements personnels.`,
    `Nous collectons les renseignements suivants auprès de l’Utilisateur, lors de son inscription : `,
    {
      type: 'list',
      items: [
        `Les noms et prénoms ;`,
        `Le numéro de téléphone ;`,
        `L'adresse personnelle ;`,
        `L'adresse courriel ;`,
        `Le nom de l'entreprise ; et`,
        `Le titre au sein de l'entreprise.`
      ]
    },
    `De façon générale, nous sommes également susceptibles de connaître votre localisation, votre adresse IP et des données concernant votre utilisation de la Plateforme et tout autre renseignement permettant de vous fournir nos produits de la façon la plus adéquate qui soit.`,
    `Les renseignements de l’Utilisateur sont également collectés par le biais de l’interaction pouvant s’établir entre l’Utilisateur et la Plateforme et peuvent être utilisés pour les finalités suivantes :`,
    {
      type: 'list',
      items: [
        `Comprendre l'utilisation de la Plateforme par l'Utilisateur ;`,
        `Améliorer la Plateforme ;`,
        `Fournir des services à l'Utilisateur ;`,
        `Gérer les comptes clients ;`,
        `Gérer les relations avec les clients et les relations commerciales ;`,
        `Fournir les renseignements personnels à des partenaires commerciaux ;`,
        `Respecter les lois et réglementations en matière de conformité ;`,
        `Envoyer des infos-lettres ; et`,
        `Envoyer des promotions adaptées aux besoins de l'Utilisateur.`
      ]
    },
    `Les renseignements personnels de l’Utilisateur ne seront pas conservés au-delà des raisons pour lesquelles ils ont été recueillis. FEIA respecte la période de conservation établie par la Présente politique, laquelle est conforme à la Loi sur la protection des renseignements personnels. La suppression des données a lieu lorsque l’Utilisateur supprime son compte. L’Utilisateur aura alors trente (30) jours pour récupérer ses données, à défaut de quoi FEIA les supprimera définitivement. `,
    `FEIA peut également compiler sous forme de listes vos noms et adresses de correspondance à des fins philanthropiques, statistiques anonymisées ou d’étude de marché. Si vous ne désirez pas que vos noms et adresses de correspondance soient compilés à ces fins, veuillez communiquer avec nous en écrivant à l’adresse courriel suivante : admin@forum-americas.org.`,
    `La suppression des renseignements personnels a lieu lorsque l’Utilisateur en fait la demande écrite à l’adresse susmentionnée. FEIA se réserve le droit de refuser la demande d’un Utilisateur lorsque ce dernier continue d’utiliser, d’une quelconque façon, les services de FEIA respecte la période de conservation établie par la présente politique, laquelle est conforme à la Loi sur la protection des renseignements personnels dans le secteur privé. Par conséquent, et sauf si le cadre réglementaire en dispose autrement, la suppression des données sera effective trente (30) jours après le traitement de la demande de suppression des renseignements personnels par le responsable de la protection des renseignements personnels.`,
    `La Plateforme est conçue pour une utilisation par des adultes seulement tel qu'entendu dans la province de Québec. FEIA ne collecte pas sciemment des renseignements personnels de la part de personnes de moins de dix-huit (18) ans. La Plateforme n’est pas conçue pour des personnes de moins de dix-huit (18) ans.`,
    `Pour de plus amples renseignements sur la protection de vos renseignements personnels et vos droits afférents, vous pouvez communiquer avec le Commissariat à la protection de la vie privée du Canada ou la Commission d’accès à l’information du Québec.`,
    {
      type: 'list',
      items: [
        `Commissariat à la protection de la vie privée du Canada www.privcom.gc.ca`,
        `Commission d’accès à l’information du Québec www.cai.gouv.qc.ca`
      ]
    },
    { type: 'title', text: 'FORMULAIRES ET INTERACTIONS' },
    `FEIA recueille vos renseignements personnels en respectant les exigences légales. Dans la mesure du possible et s’il y a lieu, nous recueillons vos renseignements personnels directement auprès de vous ou auprès des personnes que vous avez choisies pour nous fournir vos renseignements personnels.`,
    `Les renseignements personnels de l’Utilisateur peuvent être collectés par le biais de formulaires, à savoir :`,
    {
      type: 'list',
      items: [
        `Formulaire d'inscription à la Plateforme ;`,
        `Formulaire de commande ;`,
        `Formulaire de mise à jour du profil utilisateur ;`,
        `Autorisation d'accès de l'utilisateur à ses informations ;`,
        `Formulaire de contact ; et`,
        `Formulaire d'assistance.`
      ]
    },
    `Vos renseignements personnels de base, tels que vos courriel, nom, prénom, et numéro de téléphone, peuvent aussi être recueillis sur notre Plateforme, notamment par courriel.`,
    { type: 'title', text: 'INFORMATION PUBLICITAIRE' },
    `FEIA communiquera avec vous uniquement si vous le souhaitez. `,
    `Si l’Utilisateur n’est pas abonné à notre infolettre, n’a pas de relation commerciale avec FEIA ni n’a jamais participé à l’un de nos événements, il ne recevra aucun courriel de la part de FEIA, sans y avoir préalablement consenti.`,
    `Si vous préférez ne pas recevoir d’information publicitaire de notre part ou si vous ne souhaitez pas que nous utilisions vos renseignements personnels pour rehausser votre expérience en tant qu’Utilisateur, veuillez-nous le faire savoir en communiquant avec nous au contact indiqué à la section 'Droit d’opposition et de retrait' ci-dessous.`,
    `N’oubliez pas d’indiquer votre nom au complet et l’adresse électronique que vous avez utilisés lorsque vous vous êtes inscrit ou lorsque vous avez fait un achat auprès de nous. Il se peut qu’il puisse y avoir un délai de traitement pour une demande envoyée par courriel ou par la poste et que vous continuiez entre-temps à recevoir des communications de la part du FEIA. `,
    { type: 'title', text: 'LOIS ANTI-POURRIEL' },
    `FEIA se conforme aux exigences de la Loi canadienne Anti-Pourriel (LCAP).`,
    `L’Utilisateur comprend qu’il peut consentir expressément ou tacitement à recevoir des messages de la part de FEIA.`,
    `Le consentement expresse est le consentement demandé à l’Utilisateur, consentement qui doit être énoncé en termes simples et clairs, et doit inclure les fins auxquels le consentement est sollicité, les renseignements réglementaires permettant d’identifier la personne qui sollicite le consentement et, s’il est sollicité au nom d’une autre personne, les renseignements réglementaires permettant d’identifier celle-ci ; ainsi que tout autre renseignement précisé par les règlements pris en vertu de cette loi.`,
    `L’Utilisateur comprend qu’il donne son consentement tacite lors des relations d’affaires en cours ou des relations privées en cours ; lorsque l’Utilisateur publie son adresse électronique sans mentionner qu’il ne désire pas recevoir de messages électroniques commerciaux non-sollicités ; ou lorsque le message est envoyé dans les autres circonstances prévues par les règlements pris en vertu de cette loi.`,
    {
      type: 'list',
      items: [
        `Pour les fins de cette Politique, relations d’affaires en cours réfère aux relations commerciales établies entre deux personnes telles qu’un achat ou un la participation à l’un des événements organisés par le FEIA au cours de deux ans précédant la date d’envoi du message ; un investissement ou le troc d’une chose ou d’un service; tout contrat en vigueur ou venu à échéance au cours de cette période ; ou une demande au cours des six mois précédant la date d’envoi du message.`,
        `Pour les fins de cette Politique, relation privée en cours s’entend des relations entre deux personnes autres que les relations d’affaires, telles qu’un don ou un cadeau, un travail effectué à titre de bénévole ; et d’une adhésion, au sens de règlements ; le tout au cours des deux précédant la date d’envoi du message.`
      ]
    },
    `Pour vous désinscrire de notre liste d’envoi de communications électroniques, il vous suffit de nous contacter à admin@forum-americas.org.`,
    { type: 'title', text: 'PROTECTION CONTRE LA FRAUDE ET LE VOL DE VOS RENSEIGNEMENTS PERSONNELS' },
    `FEIA s’engage en tout temps à respecter les lois et règlements applicables à son activité et à prendre toutes les mesures utiles et nécessaires afin de détecter et prévenir la négligence, la fraude et le vol de vos informations personnelles. Toutefois, bien que FEIA prenne des précautions, FEIA ne peut garantir qu’aucun cas de fraude ou de vol de vos renseignements personnels ne peut avoir lieu.`,
    `FEIA tient un registre des incidents de confidentialité à jour et prendra contact avec tout Utilisateur dont les renseignements personnels seraient l’objet d’un tel incident et présentant un risque de préjudice sérieux.`,
    { type: 'title', text: 'PUBLICITÉ TIERCE AFFICHÉE SUR NOTRE PLATEFORME' },
    `Vous pouvez éventuellement trouver de la publicité tierce affichée sur notre Plateforme. Nous n’autorisons pas des tiers à recueillir vos renseignements personnels sur notre Plateforme sans votre consentement.`,
    `FEIA interdit et n’est pas responsable de la revente ou de l’utilisation par des tiers des « opt-in » obtenus à travers la Plateforme par ces tiers. Si vous avez accepté de partager vos coordonnées à des organisations partenaires, alors, nous les leur partagerons.`,
    `Nous ne vendons ni ne louons vos renseignements personnels sans votre consentement.`,
    { type: 'title', text: 'SERVICES TIERS ' },
    `FEIA pourra utiliser, dans le cadre de sa prestation de service et/ou la gestion de son activité et de sa Plateforme, des services tiers. En aucun cas FEIA n’est responsable de tout préjudice pouvant provenir de ces services tiers. `,
    `Pour connaitre les conditions d’utilisation de ces services, nous invitons l’Utilisateur à consulter leur site web respectif. `,
    { type: 'title', text: 'DONNÉES ÉCHANGÉES AUTOMATIQUEMENT' },
    `Grâce aux fichiers témoins (ci-après les « <strong>Cookies</strong> »), nous recueillons certaines informations lors d’échanges entre votre ordinateur et notre serveur. Les Cookies sont des fichiers créés par un site Web qui permettent d’enregistrer des informations relatives à la navigation de votre ordinateur sur le site. Ils sont stockés automatiquement sur vos appareils électroniques, vous permettent de vous connecter à la Plateforme lors d’utilisations ultérieures et permettent à FEIA de personnaliser la Plateforme.`,
    `Il s’agit principalement des informations suivantes : `,
    {
      type: 'list',
      items: [
        `Nom de domaine ;`,
        `Adresse IP ;`,
        `Historique de navigation ;`,
        `Langue de l'Utilisateur ; et`,
        `Emplacement de l'Utilisateur.`
      ]
    },
    `La plupart des informations transmises automatiquement ne permettent pas de connaître l’identité de l’Utilisateur. Elles ne sont recueillies qu’en raison d’exigences technologiques inhérentes à la navigation sur Internet. La plupart des navigateurs acceptent automatiquement les Cookies, mais il est généralement possible de modifier ce comportement. Cependant, si l’Utilisateur choisit de refuser les Cookies, il ne pourra peut-être pas s’identifier, ni utiliser certaines fonctions de la Plateforme.`,
    `Le recours à de telles informations nous permet d’améliorer le service, de personnaliser l’expérience de l’Utilisateur et de faire un meilleur suivi de votre utilisation antérieure.`,
    `Outre les méthodes décrites ci-dessus, nous pouvons aussi recueillir des renseignements au moyen de Cookies ou de technologies similaires notamment lors de vos visites sur notre Plateforme. Ces méthodes nous permettent de recueillir divers types de renseignements, y compris les pages que vous visitez, les courriels que vous lisez ainsi que d’autres renseignements.`,
    `Ces informations servent également à :`,
    {
      type: 'list',
      items: [
        `Identifier l'Utilisateur ;`,
        `Inscrire l'Utilisateur, notamment à un évènement ;`,
        `Traiter les demandes et les commandes de l'Utilisateur et répondre à ses questions ;`,
        `Rester en contact avec l'Utilisateur et lui fournir en permanence un service personnalisé ;`,
        `Communiquer avec l'Utilisateur (par courriel, par la poste ou autrement) et lui faire parvenir du matériel publicitaire ou de l'information supplémentaire sur les événements, services, sondages, concours, promotions et offres spéciales ;`,
        `En savoir plus sur les préférences d'achat de l'Utilisateur ;`,
        `Développer de nouveaux produits et services ; et`,
        `Effectuer des études de marché et des recherches sur le rendement afin d'évaluer le service à la clientèle, de mesurer le rendement, de rehausser l'expérience procurée à l'Utilisateur et améliorer les produits.`
      ]
    },
    `Ces Cookies sont multiples, certains expirent lorsque l’Utilisateur ferme son navigateur et n’ont plus d’effet alors que d’autres peuvent être conservés. En tout temps, l’Utilisateur peut désactiver les Cookies. Dans cette éventualité, FEIA ne garantit pas un fonctionnement optimal de la Plateforme.`,
    `Il est aussi possible d’interdire l’enregistrement de Cookies en configurant votre logiciel de navigation Internet. Toutefois, si vous choisissez cette dernière option, il se peut que vous ne soyez pas en mesure de profiter de l’ensemble de la Plateforme.`,
    { type: 'title', text: 'COMMUNICATIONS INTERNATIONALES' },
    `Dans certaines situations il se pourrait que les renseignements personnels de l’Utilisateur transitent par des serveurs à l’extérieur du Canada.`,
    `Il se peut aussi que FEIA communique vos renseignements personnels à des tierces parties situées à l’extérieur du Québec, notamment dans l’Union Européenne, et détenus par des sociétés situées dans les territoires mentionnés ci-avant et soumises à leur législation. `,
    `L’Utilisateur reconnaît et dégage le FEIA de toute responsabilité des conséquences d’un tel transfert. `,
    `FEIA s’engage à ne pas communiquer vos renseignements personnels avec des tiers situés dans des juridictions qui n’offrent pas un niveau de protection des renseignements personnels similaire à celui du Québec.`,
    { type: 'title', text: 'PARTAGE DES RENSEIGNEMENTS PERSONNELS' },
    `FEIA peut partager dans certaines circonstances les renseignements personnels collectés de l’Utilisateur. Les situations où les renseignements personnels peuvent être partagés sont le partage aux partenaires de l'entreprise, le partage aux commanditaires et/ou annonceurs et l'application de la loi ou de demandes légales.`,
    `L’Utilisateur comprend que FEIA peut en tout temps partager ses renseignements personnels lors d’une cession partielle ou totale de l’activité à un tiers. Dans un tel cas, les renseignements cédés en tant qu’actifs de FEIA seront soumis aux mêmes garanties que celles de la présente Politique. `,
    `FEIA peut également re-publier ou re-partager publiquement des renseignements permettant de vous identifier lorsque vous publiez du contenu en relation avec FEIA sur des Médias sociaux. De plus, il se peut qu’en cliquant sur un lien externe situé sur la Plateforme, vous accédiez à des sites internet externes qui possèdent leur propre politique de protection des renseignements personnels. Il est donc avisé que vous les consultiez, car vous y serez soumis.`,
    `Si l’Utilisateur ne souhaite pas que ses renseignements personnels soient communiqués à des tiers ou des partenaires, il lui est possible de s’y opposer à tout moment, comme mentionné dans la section 'Droit d’opposition et de retrait' suivante.`,
    `FEIA peut, dans les cas suivants, communiquer exceptionnellement les renseignements personnels de l’Utilisateur sans son consentement :`,
    {
      type: 'list',
      items: [
        `En cas d’obligation légale, notamment lorsqu’une ordonnance d’un Tribunal oblige FEIA à communiquer les renseignements personnels de l’Utilisateur.`,
        `Lorsqu’il est impossible d’obtenir le consentement de l’Utilisateur et que les lois sur la protection de la vie privée permettent à FEIA de communiquer ces renseignements personnels. C’est notamment le cas lorsque la protection de l’intérêt public l’exige. `
      ]
    },
    { type: 'title', text: 'DROIT D’OPPOSITION ET DE RETRAIT' },
    `Nous nous engageons à offrir à l’Utilisateur un droit d’opposition et de retrait quant à ses renseignements personnels. `,
    `Le droit d’opposition s’entend comme étant la possibilité offerte aux internautes de refuser que leurs renseignements personnels soient utilisés à certaines fins mentionnées lors de la collecte. `,
    `Le droit de retrait s’entend comme étant la possibilité offerte aux internautes de demander à ce que leurs renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion. `,
    `Conformément à la section suivante, l’Utilisateur est également en droit de demander d’avoir accès aux renseignements personnels que nous avons recueillis à son sujet et de les corriger au besoin, ainsi que d’exercer son droit d’opposition ou son droit de retrait, en s’adressant au responsable de la protection des renseignements personnels, dont il trouvera les coordonnées à la section 'Personne-ressource' ci-dessous.`,
    { type: 'title', text: 'ACCÈS À VOS RENSEIGNEMENTS PERSONNELS ' },
    `Outre les cas de partages mentionnés dans cette Politique, vos renseignements personnels recueillis par FEIA en vertu des présentes seront accessibles seulement par les employés autorisés et concernés de FEIA. `,
    `FEIA, sous réserve de certaines exceptions, pourra vous informer des renseignements personnels qui vous concernent, de l’utilisation qui en est faite et du fait qu’ils ont été communiqués à des Prestataires tiers. Vous pourrez également avoir accès à ces renseignements pour toute modification ou suppression.`,
    `Votre demande d’accès doit être faite par écrit et envoyée selon la procédure prévue dans la présente section et dans la section 'Personne ressource' ci-dessous.`,
    `FEIA accusera réception de votre demande par écrit et pourra vous demander de lui confirmer votre identité avant de vous fournir ces renseignements. En principe, l’accès à ces renseignements sera fourni gratuitement, mais, si vous demandez une copie ou une transmission de vos renseignements, nous pourrions exiger des frais raisonnables. FEIA vous informera du montant à l’avance et vous donnera la possibilité de retirer votre demande. FEIA vous donnera accès à vos renseignements dans les trente (30) jours suivant la réception de votre demande ou vous fournira un avis écrit pour vous aviser qu’un délai supplémentaire est nécessaire pour répondre à votre demande.`,
    `Dans certains cas, nous pourrions être incapables de vous donner accès aux renseignements vous concernant, en totalité ou en partie. Si nous en sommes incapables ou si la loi ne nous le permet pas, nous vous fournirons une explication et indiquerons les autres mesures qui sont à votre disposition.`,
    { type: 'title', text: 'SÉCURITÉ' },
    `Les renseignements personnels que FEIA collecte sont conservés dans un environnement sécurisé. FEIA a adopté des mesures appropriées afin de préserver la confidentialité des renseignements personnels et de les protéger contre la perte ou le vol, ainsi que l’accès, la communication, la reproduction, l’utilisation ou la modification non autorisés, en tenant compte, notamment, de leur sensibilité et des fins auxquelles ils sont employés. De plus, FEIA exige que chaque employé, mandataire ou représentant de FEIA respecte la présente Politique.`,
    `Pour assurer la sécurité des renseignements personnels de l’Utilisateur, FEIA a recours, entre autres, aux mesures suivantes :`,
    {
      type: 'list',
      items: [
        `Protocole SSL (Secure Sockets Layers) ;`,
        `Accès sécurisé aux locaux ;`,
        `Accès protégé aux serveurs et aux ordinateurs ;`,
        `Pare-feu ;`,
        `Utilisation de réseaux de données protégés avec mot de passe ;`,
        `Ententes de confidentialité avec les employés ;`,
        `Politiques de protection des renseignements personnels à l'interne ;`,
        `Procédures de protection des données personnelles ;`,
        `Mise à jour courante des mesures de sécurité ; et`,
        `Communications électroniques cryptées et sécurisées.`
      ]
    },
    `FEIA s’engage ainsi à maintenir un haut degré de sécurité en intégrant les dernières innovations technologiques permettant d’assurer la confidentialité des transactions de l’Utilisateur afin d’assurer la protection de ses données personnelles et éviter qu’elles ne soient consultées, utilisées ou divulguées sans autorisation. FEIA s’engage à tenir un registre des incidents de confidentialité.`,
    `Toutefois, comme aucun mécanisme n’offre une sécurité maximale, une part de risque est toujours présente lorsqu’on utilise Internet pour transmettre des renseignements personnels. Si un mot de passe est utilisé pour protéger le compte de l’Utilisateur et ses informations personnelles, il est de sa responsabilité de faire en sorte qu’il reste confidentiel. `,
    `En cas d’incident de confidentialité impliquant les renseignements personnels d’un Utilisateur détenus par FEIA, FEIA avisera l’Utilisateur concerné et la Commission d’accès à l’information du Québec de tout incident de confidentialité présentant un risque de préjudice sérieux.`,
    { type: 'title', text: 'LANGUE FRANÇAISE ET ANGLAISE' },
    `En cas de problème de définition ou d’interprétation entre les versions française et anglaise de ces Conditions d’utilisation, la version française prévaudra.`,
    { type: 'title', text: 'DROIT APPLICABLE ET TRIBUNAUX COMPÉTENTS' },
    `La Plateforme et son contenu sont gérés par FEIA à partir de ses bureaux situés dans la province de Québec au Canada. La Plateforme, les Médias sociaux et leur contenu, les présentes Mentions légales et l’utilisation qu’en fait l’Utilisateur sont tous régis par les lois du Québec et du Canada qui sont applicables, sans donner effet à quelque principe que ce soit relatif au conflit de lois. `,
    `Vous acceptez que l'ensemble des questions et des litiges concernant la Plateforme les Médias sociaux et l’utilisation de ceux-ci soient d’abord portés devant un médiateur et, en l’absence de compromis, tranchés par les tribunaux de Montréal.`,
    { type: 'title', text: 'PERSONNE-RESSOURCE' },
    `Pour toute question au sujet du traitement ou à la mise à jour de vos renseignements personnels ou pour nous faire part de toute préoccupation à cet égard, veuillez communiquer avec nous par le biais de l’adresse courriel suivante : admin@forum-americas.org. La responsable de la protection des renseignements personnels prendra contact avec vous dans les trente (30) jours suivant la réception de votre courriel.`,
    { type: 'title', text: 'INTERPRÉTATION' },
    `La Présente Politique est régie par les lois québécoises et canadiennes applicables, incluant la Loi sur la protection des renseignements personnels dans le secteur privé du Canada. Ces lois s’appliquent sans donner effet à quelque principe que ce soit relatif au conflit de lois. `,
    `Chaque fois que le contexte l’exige, tout mot écrit au singulier comprend aussi le pluriel et vice-versa ; tout mot écrit au genre masculin comprend aussi le genre féminin et vice-versa.`,
    { type: 'title', text: 'DIVISIBILITÉ' },
    `Chaque disposition de la présente Politique forme un tout distinct de sorte que toute décision d’un tribunal à l’effet que l’une des dispositions de la présente Politique est nulle ou non exécutoire n’affecte aucunement la validité des autres dispositions de la présente Politique ou encore leur caractère exécutoire.  `,
    { type: 'title', text: 'MODIFICATION DE CETTE POLITIQUE ' },
    `Nous mettons occasionnellement à jour cette Politique. L’utilisation continue de la Plateforme constitue l’accord de l’Utilisateur quant à cette Politique de confidentialité ainsi qu’à ses mises à jour. `,
    `Nous tenterons, dans la mesure du possible, d’informer les Utilisateurs de tout changement apporté à la Politique. Il incombe cependant à l’Utilisateur de l’examiner régulièrement afin d’être informé des changements qui y sont apportés. `
  ]
}
