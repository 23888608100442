<template>
  <article>
    <section class="page-header -smaller">
      <h1 class="page-header__title">{{ $t('common.navigation.contact') }}</h1>
    </section>

    <section class="layout-section -no-top">
      <p>info@forum-americas.org</p>
    </section>
  </article>
</template>

<script>
export default {
  name: 'Terms'
}
</script>
