import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { EventBusVm } from '../plugins/eventBus'
import en from './en'
import es from './es'
import fr from './fr'

const messages = { en, es, fr }
export const supportedLocales = Object.keys(messages)
const [systemLocale] = Intl.DateTimeFormat().resolvedOptions().locale.split('-')
const esUrl = /^\/es\//.test(window.location.pathname) ? 'es' : null
const frUrl = /^\/fr\//.test(window.location.pathname) ? 'fr' : null
const localStoreLocale = localStorage.getItem('feia-lang')
const storedLocale = supportedLocales && supportedLocales.includes(localStoreLocale) ? localStoreLocale : null
const urlLocale = esUrl || frUrl
const fallbackLocale = supportedLocales.includes(systemLocale) ? systemLocale : 'en'
const locale = urlLocale || storedLocale || fallbackLocale

Vue.use(VueI18n)
const i18n = new VueI18n({ locale, messages })
EventBusVm.contentLoaded = true
export default i18n
