export default Vue => {
  Vue.mixin({
    methods: {
      eventThumbFallback(eventName, thumbnail) {
        if (thumbnail && thumbnail !== 'false') return thumbnail
        const name = eventName.replace(/-(.)/g, ($0, $1) => $1.toUpperCase())
        return `/images/events/${name}/event-thumbnail.png`
      }
    }
  })
}
