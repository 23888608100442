<template>
  <Transition name="modal">
    <section v-if="isOpen" class="modal" @click="$emit('close')">
      <article class="modal__box -bigger" @click.stop>
        <button class="modal__close" @click="$emit('close')">
          <svg-icon id="close" />
        </button>

        <header class="modal__header">
          <h4 v-if="title" class="layout-grid__title">{{ title }}</h4>
          <h6 v-if="subtitle" class="layout-grid__subtitle -accent">{{ subtitle }}</h6>
        </header>

        <div v-if="content" class="modal__content">{{ content }}</div>

        <div class="modal__button">
          <button class="button -secondary -darker" @click="$emit('close')">{{ $t('common.navigation.close') }}</button>
        </div>
      </article>
    </section>
  </Transition>
</template>

<script>
export default {
  name: 'OverlayModal',
  props: {
    isOpen: { type: Boolean, default: false },
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    content: { type: String, default: null }
  }
}
</script>
