<template>
  <nav class="navigation-top">
    <section class="navigation-top__container">
      <LocaleLink v-appear:right :to="{ name: 'home' }" class="navigation-top__logo">
        <img :src="$t('common.logoSmall')" alt="Logo" class="navigation-top__image -small" />
        <h6 class="navigation-top__logo-tv">TV</h6>
      </LocaleLink>

      <div v-appear:up="{ delay: 75 }" class="navigation-top__list">
        <LocaleLink :to="{ name: 'home' }" class="navigation-top__item -home">{{
          $t('common.navigation.home')
        }}</LocaleLink>

        <div class="navigation-top__conferences -mobile" @click.stop="onClickConferencePicker">
          <button class="navigation-top__item -dropdown">
            <p>{{ $t('conferences.title') }}</p>
            <svg-icon id="arrow" />
          </button>

          <DropDown :is-open="isConferencePickerOpen" class="dropdown" content-class="dropdown__content">
            <LocaleLink
              v-for="conference in conferences"
              :key="conference.slug"
              :to="{ name: 'conference', params: { slug: conference.slug } }"
              class="dropdown__item"
            >
              {{ conference.title }}
            </LocaleLink>
          </DropDown>
        </div>

        <div
          class="navigation-top__conferences"
          @mouseenter="isConferencePickerOpen = true"
          @mouseleave="isConferencePickerOpen = false"
        >
          <button class="navigation-top__item -dropdown">
            <p>{{ $t('conferences.title') }}</p>
            <svg-icon id="arrow" />
          </button>

          <DropDown :is-open="isConferencePickerOpen" class="dropdown" content-class="dropdown__content">
            <LocaleLink
              v-for="conference in conferences"
              :key="conference.slug"
              :to="{ name: 'conference', params: { slug: conference.slug } }"
              class="dropdown__item"
            >
              {{ conference.title }}
            </LocaleLink>
          </DropDown>
        </div>

        <div v-appear="{ delay: 300 }" class="navigation-top__lang">
          <button @click.stop="isLangPickerOpen = !isLangPickerOpen">
            <svg-icon id="language" />
            {{ langSwitcherLabel }}
          </button>

          <DropDown :is-open="isLangPickerOpen" class="dropdown" content-class="dropdown__content">
            <RouterLink v-for="lang in langLinks" :key="lang.label" :to="lang.to" class="dropdown__item">
              {{ lang.label }}
            </RouterLink>
          </DropDown>
        </div>
      </div>
    </section>
  </nav>
</template>

<script>
import getLocalizedRoute from '@/helpers/getLocalizedRoute'

export default {
  name: 'NavigationTop',
  data() {
    return {
      isLangPickerOpen: false,
      isConferencePickerOpen: false
    }
  },
  computed: {
    langSwitcherLabel() {
      return this.$i18n.locale.toUpperCase()
    },
    langLinks() {
      return [
        { to: getLocalizedRoute('en', { ...this.$route }), label: this.$t('common.languages.english') },
        { to: getLocalizedRoute('fr', { ...this.$route }), label: this.$t('common.languages.french') }
      ]
    },
    slug() {
      return this.$route.params.slug
    },
    conferences() {
      return this.$api.conferenceOrder.map(({ slug }) => this.$t(`conferences.index.${slug}`))
    }
  },
  watch: {
    isLangPickerOpen(isOpen) {
      if (isOpen) document.body.addEventListener('click', this.closeLangPicker)
      else document.body.removeEventListener('click', this.closeLangPicker)
    },
    isConferencePickerOpen(isOpen) {
      if (isOpen) document.body.addEventListener('click', this.closeConferencePicker)
      else document.body.removeEventListener('click', this.closeConferencePicker)
    }
  },
  methods: {
    getLangLink(lang) {
      const link = getLocalizedRoute(lang, { ...this.$route })
      return link
    },
    closeLangPicker() {
      this.isLangPickerOpen = false
      document.body.removeEventListener('click', this.closeLangPicker)
    },
    closeConferencePicker() {
      this.isConferencePickerOpen = false
      document.body.removeEventListener('click', this.closeConferencePicker)
    },
    onClickConferencePicker() {
      this.isConferencePickerOpen = !this.isConferencePickerOpen
    }
  }
}
</script>

<style lang="scss">
.peekaboo {
  &-enter-active,
  &-leave-active {
    transition: transform 240ms cubic-bezier(0.6, 0.05, 0.01, 0.99), opacity 120ms linear;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  &-leave,
  &-enter-to {
    transform: translateZ(0);
  }
}
</style>
