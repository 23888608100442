import common from './common'
import conferences from './conferences'
import terms from './terms'
import privacy from './privacy'
import faq from './faq'
import home from './home'

export default {
  common,
  conferences,
  terms,
  privacy,
  faq,
  home
}
