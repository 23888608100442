<template>
  <article v-if="previousEvents.length" class="layout-section -bg-light">
    <section class="layout-section__hgroup">
      <h2 class="layout-section__title">{{ $t('conferences.onDemand.title') }}</h2>
      <p>{{ $t('conferences.onDemand.description') }}</p>
    </section>

    <!-- <legend class="layout-section__legend">
      <p>{{ $t('conferences.onDemand.noStreams') }}</p>
    </legend> -->

    <section class="layout-grid -slider">
      <ConferenceIndexItem v-for="event in previousEvents" :key="event.edition" :event="event" />
    </section>
  </article>
</template>

<script>
import moment from 'moment'
import ConferenceIndexItem from './ConferenceIndexItem.vue'

const now = moment()

export default {
  name: 'ConferenceIndex',
  components: { ConferenceIndexItem },
  props: {
    conference: { type: Object, required: true }
  },
  computed: {
    previousEvents() {
      return this.$api.conferences[this.conference.slug].filter(
        event => now.isAfter(event.endDate) && event.sessions.length > 0
      )
    }
  }
}
</script>
