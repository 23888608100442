<template>
  <menu :class="className">
    <div class="navigation-side__content">
      <header class="navigation-side__header">
        <LocaleLink v-appear:right :to="{ name: 'home' }" class="navigation-top__logo -small">
          <img src="/images/brand/logo-small-white.png" alt="Logo" />
        </LocaleLink>

        <figure class="navigation-side__close" @click="$emit('close')">
          <svg-icon id="close" />
        </figure>
      </header>

      <nav class="navigation-side__list">
        <LocaleLink
          v-for="({ label, link }, i) in links"
          :key="label"
          :to="link"
          :exact="!i"
          :style="{ transitionDelay: `${200 + i * 50}ms` }"
          class="navigation-side__item navigation-side__cascade-item"
          @click.native="$emit('close')"
        >
          {{ label }}
        </LocaleLink>

        <template v-for="lang in langLinks">
          <RouterLink
            :key="lang"
            v-appear="{ delay: 250 }"
            :to="getLangLink(lang)"
            class="navigation-side__item"
            @click.native="$emit('close')"
          >
            <span>{{ lang.toUpperCase() }}</span>
          </RouterLink>
        </template>
      </nav>
    </div>
  </menu>
</template>

<script>
import getLocalizedRoute from '@/helpers/getLocalizedRoute'

export default {
  name: 'NavigationSide',
  props: {
    isOpen: { type: Boolean, default: false }
  },
  computed: {
    links() {
      return [{ link: { name: 'home' }, label: this.$t('common.navigation.home') }]
    },
    className() {
      return ['navigation-side', { '-open': this.isOpen }]
    },
    langLinks() {
      // return supportedLocales.filter(lang => lang !== this.$i18n.locale)
      return ['en', 'fr'].filter(lang => lang !== this.$i18n.locale)
    }
  },
  methods: {
    getLangLink(lang) {
      const link = getLocalizedRoute(lang, this.$route)
      return link
    }
  }
}
</script>
