<template>
  <article class="page-header -smaller">
    <h1 class="page-header__title">{{ $t('faq.title') }}</h1>
  </article>
</template>

<script>
export default {
  name: 'FaqHeader'
}
</script>
