<template>
  <article class="layout-viewer">
    <section class="layout-viewer__container">
      <figure class="layout-viewer__video video">
        <iframe
          :key="videoId"
          :src="`https://player.vimeo.com/video/${videoId}`"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          class="video__player"
          allowfullscreen
        />
      </figure>

      <article class="layout-viewer__content">
        <h2 class="layout-viewer__heading">{{ currentSession.title }}</h2>
        <p v-if="currentSession.description">{{ currentSession.description }}</p>

        <p>
          {{ $t('common.viewer.publication') }} {{ publishDate }}.
          <template v-if="event.website"
            >{{ $t('common.viewer.learnMore') }}
            <a :href="event.website" target="_blank" rel="noopener" class="link">{{ $t('common.viewer.website') }}</a
            >.</template
          >
        </p>
      </article>
    </section>

    <StreamingViewerPanel :sessions="event.sessions" :current-session.sync="currentSessionIndex" />
  </article>
</template>

<script>
import moment from 'moment'
import StreamingViewerPanel from './StreamingViewerPanel.vue'

export default {
  name: 'StreamingViewer',
  components: { StreamingViewerPanel },
  props: {
    slug: { type: String, required: true },
    year: { type: String, required: true },
    conference: { type: Object, required: true },
    event: { type: Object, required: true }
  },
  data() {
    return {
      currentSessionIndex: 0
    }
  },
  computed: {
    currentSession() {
      return this.event.sessions[this.currentSessionIndex]
    },
    publishDate() {
      return moment(this.event.startDate).format('MMMM YYYY')
    },
    videoId() {
      if (!this.currentSession) return
      const [fallback] = Object.values(this.currentSession.videoId).filter(Boolean)
      return this.currentSession.videoId[this.$i18n.locale] || fallback
    }
  }
}
</script>
