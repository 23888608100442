<template>
  <section class="viewer-panel">
    <h3 class="viewer-panel__title">Sessions ({{ sessions.length }})</h3>

    <div class="viewer-panel__content">
      <ScrollBox class="viewer-panel__list">
        <button
          v-for="(session, index) in sessions"
          :key="index"
          class="viewer-panel__item -button"
          :class="{ '-active': currentSession === index }"
          @click="$emit('update:currentSession', index)"
        >
          <h4 clas="viewer-panel__heading">{{ session.title }}</h4>
        </button>
      </ScrollBox>
    </div>
  </section>
</template>

<script>
export default {
  name: 'StreamingViewerPanel',
  props: {
    sessions: { type: Array, default: Array },
    currentSession: { type: Number, default: 0 }
  }
}
</script>
