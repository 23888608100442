<template>
  <section>
    <StreamingHeader v-bind="{ slug, year, conference, event }" />
    <StreamingViewer v-bind="{ slug, year, conference, event }" />
    <StreamingAbout v-bind="{ slug, year, conference, event }" />
  </section>
</template>

<script>
import * as partials from './partials'
import getLocalizedRoute from '@/helpers/getLocalizedRoute'

export default {
  name: 'Streaming',
  components: { ...partials },
  props: {
    slug: { type: String, required: true },
    year: { type: String, required: true }
  },
  computed: {
    conference() {
      return this.$t(`conferences.index.${this.slug}`)
    },
    event() {
      const events = this.$api.conferences[this.slug]
      return events.find(event => event.year === this.year)
    }
  },
  created() {
    if (!this.year) this.$router.push(getLocalizedRoute({ name: 'home' }))
  }
}
</script>
