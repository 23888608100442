<template>
  <div class="button -controls">
    <button class="button__control -disabled -left">
      <svg-icon id="arrow-medium" />
    </button>

    <button class="button__control">
      <svg-icon id="arrow-medium" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'ActionSlide'
}
</script>
