<template>
  <article class="layout-section -no-top">
    <section class="layout-dropdown">
      <template v-for="{ key, question, answer } in entriesWithKeys">
        <article :key="`${key}-header`" v-appear class="layout-dropdown__item">
          <header class="layout-dropdown__title" @click="toggleSection(key)">
            {{ question }}
            <figure class="layout-dropdown__icon" :class="{ '-active': key === expandedSection }">+</figure>
          </header>

          <DropDown :key="key" :is-open="key === expandedSection" content-class="layout-dropdown__content">
            <div v-html="answer" />
          </DropDown>
        </article>
      </template>
    </section>
  </article>
</template>

<script>
export default {
  name: 'FaqIndex',
  data() {
    return { expandedSection: null }
  },
  computed: {
    entriesWithKeys() {
      return this.$t('faq.index').map((entry, index) => ({ ...entry, key: index }))
    }
  },
  methods: {
    toggleSection(key) {
      this.expandedSection = key === this.expandedSection ? null : key
    }
  }
}
</script>
