<template>
  <article class="layout-section -narrow -bg-white layout-slider layout-grid">
    <section class="layout-slider__item">
      <figure class="grid__figure" />
    </section>

    <section class="layout-slider__item">
      <figure class="grid__figure" />
    </section>

    <section class="layout-slider__item">
      <figure class="grid__figure" />
    </section>

    <section class="layout-slider__item">
      <figure class="grid__figure" />
    </section>
  </article>
</template>

<script>
export default {
  name: 'HomeVideo'
}
</script>
