export default {
  title: 'Terms of use',
  update: 'Last update on May 15, 2023.',
  meta: {
    title: 'Terms of use'
  },
  wp: 'false',
  content_static: [
    `Please read carefully the following terms of use carefully (hereinafter the “Terms of Use”) of International Economic Forum of the Americas Inc., a company having its head office at 620-606 Cathcart Street (H3B1K9), in the city of Montreal, province of Quebec, Canada (hereinafter "IEFA").`,
    `IEFA operates this website (hereinafter the “Platform”), and the social media pages, account, website, network or any application contained therein designed and maintained by IEFA (hereinafter the "Social Media").`,
    `By choosing to use the Platform and the IEFA services, the user, understood as the end user or the organization you represent or you work for (hereinafter the “User” or “Users”) agrees to all the Terms of set forth below that govern the content and use of the Platform, as well as the privacy policy set forth following the IEFA Terms of Use.`,
    `These Terms of Use are legally binding on the User and IEFA (hereinafter the "Parties"). The Parties shall be entitled to invoke these Terms of Use and to take all useful and necessary measures to enforce them.`,
    `If the User does not agree to these Terms of Use or, as the case may be, the Terms of Use as amended from time to time, the User shall not, or shall no longer, use the Platform.`,
    `Terms defined in these Terms of Use are used in IEFA's privacy policy and general terms of sale. We recommend that you read all of IEFA policies (the Terms of Use, Privacy Policy, and General Terms of Sale are hereinafter referred to jointly as "<strong>Legal Notice</strong>").`,
    { type: 'title', text: 'ACCESS AND USE OF THE PLATFORM' },
    'Browsing the Platform means that you have read, understood and accepted the Terms of Use. ',
    'The Platform is accessible from any computer (computer, smart phone, tablet, etc.) and on any browser.',
    'For optimal use, we recommend the use of the following browsers: Google Chrome, Firefox, Safari, Microsoft Edge.',
    'The Platform is intended for a professional audience. The Platform is not intended for a consumer audience. By accepting the Terms of Use, you represent and warrant that you are doing so in a professional or business capacity. By using the Platform, you warrant that you are doing so in a professional or commercial capacity rather than as a consumer or for personal use.',
    { type: 'title', text: 'THE SERVICE' },
    'The Platform is always accessible. However, the Platform may be temporarily unavailable due to updates or technical problems. IEFA is not responsible for any interruption in service or updates. IEFA attempts to make updates when traffic on the Platform is at its lowest.',
    'IEFA does not warrant that the Platform, or any portion thereof, will operate on any particular computer hardware or device. Furthermore, the Platform may be subject to malfunctions and delays inherent in the use of the Internet and electronic communications.',
    'IEFA reserves the right to add, remove and edit all or part of the content on the Platform, except for User-specific content. IEFA cannot be held responsible for any mistakes, typographical errors or bugs. Each User created in the system is the owner of the content of his account. IEFA does not delete anything unless a User requests it.',
    'The User agrees, non-restrictively, to not: ',
    {
      type: 'list',
      items: [
        'Use the Platform in any manner that could damage, disable, overburden or impair the Platform;',
        'Disrupt the security of, or otherwise abuse, the Platform or any services, resources, systems, servers or networks connected to the Platform or affiliated sites or connected to or accessible through them;',
        'Disrupt or interfere with the use or enjoyment of the Platform by other services or affiliated or linked sites;',
        'Upload, post or otherwise transmit on the Platform a virus or other harmful, disruptive or destructive computer file or program; ',
        'Use any robot, spider or other automatic device or manual process to monitor or copy the Platform pages or content; ',
        'Transmit through the Platform unsolicited bulk e-mail, chain letters, junk mail or other types of e-mail; ',
        'Attempt to gain unauthorized access to the Platform or portions thereof to which access is restricted. In addition, User agrees that it is solely responsible for all actions and communications undertaken or transmitted to or on behalf of User and agrees to comply with all applicable laws with respect to its use or activities on the Platform.'
      ]
    },
    { type: 'title', text: 'INTELLECTUAL PROPERTY' },
    'Unless otherwise noted, all intellectual property rights (trademark, copyright, etc.) (hereinafter the "Intellectual Property") related to the Platform are the exclusive property of IEFA. You may not copy or use any of the materials, structure, menu, appearance or characters that appear on the Platform without the express consent of IEFA.',
    'Certain names, words, titles, phrases, logos, icons, graphics, designs, domain names and the like contained on the Platform or Social Media may be, among other things, registered or unregistered trademarks or trade names (all of which are referred to in these Terms of Use as "Trademarks"). These Trademarks are protected by Canadian and foreign trademark laws and are the exclusive property of IEFA.',
    'Without limiting the scope thereof, the Intellectual Property owned by IEFA includes, but is not limited to, the following:',
    {
      type: 'list',
      items: [
        'All logos, designs, graphics, photos, videos, trademarks (whether registered or not), symbols, text, phrases, words and all general content on the Platform or Social Media not already subject to intellectual property rights ;',
        'All content offered by the Platform or Social Media for free download.'
      ]
    },
    'Unless otherwise noted, all information provided on the Platform or Social Media is the property of IEFA, and is protected by applicable laws. In addition, the information may not otherwise be copied, displayed, distributed, downloaded, unlicensed, modified, published, reproduced, reused, sold, transmitted or used for derivative works, public or commercial purposes without the express written permission of IEFA. The information is protected under the copyright and trademark laws of Canada and the countries that host the Platform or Social Media.',
    'You may use the information solely for your own personal use without modification, subject to your referencing on any copies the respective copyright notices and trademark attributions. These Terms of Use grant you permission to view and display the information on your computer solely for non-commercial, personal and educational purposes. This authorization is yours alone and may not be assigned, sublicensed or transferred to any other person.',
    'You are not authorized to download, copy, record or print the content of the Platform or Social Media. For further information regarding permission to use, reproduce or republish any information presented on the Platform or Social Media, please contact us at admin@forum-americas.org. Images or text contained in the Platform or Social Media which are downloaded, copied, recorded or printed remain subject to the Terms of Use. User must ensure that all trademark or copyright notices continue to appear in any content contained in the Platform or Social Media.',
    'Any unauthorized downloading, retransmission, copying or modification of the Intellectual Property or data contained on the Platform or Social Media may violate laws including trademark or copyright laws and could subject User to legal action.',
    'The content of the Platform or Social Media may be changed at any time without notice. For further information regarding permission to use, reproduce or republish any information presented on the Platform or Social Media, please contact us at admin@forum-americas.org',
    'Nothing contained herein shall be construed as conferring any license or right upon User under copyright or trademark laws.',
    'Subject to applicable laws, the importation, creation, submission or storage of content by the User grants IEFA a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license. This license permits IEFA to host, use, modify, reproduce, publish, publicly perform, create derivative works from, or distribute your content. Under this license, IEFA may use your content only in connection with the operation, promotion or improvement of its services. If you wish to terminate this license, simply delete your account. The content you have shared will still be visible to other Users: you can request its deletion, under certain conditions, from the contact person, whom you can contact at admin@forum-americas.org.',
    { type: 'title', text: 'EXTERNAL LINKS ' },
    `It is possible that by using a hyperlink, the User leaves the Platform or Social Media to access external content (hereinafter "External Websites"). IEFA makes available to Users, in addition to its own content, links to External Websites solely for the User's convenience and information. IEFA has no control over the content of these External Websites and assumes no responsibility for their accuracy, precision, completeness, authenticity, timeliness or suitability. Any liability of IEFA is excluded, in particular for direct or indirect damages or consequences related to the use of the contents of these External Websites. IEFA has no influence on the contents of the linked external websites and is not responsible for their careful selection, the contents of the external links, in particular those which have been changed after the link was set. IEFA does not require the transmission of information, does not select the recipients of the transmitted information nor the information itself, and does not modify it. Furthermore, no automatic short-term intermediate storage of these external links is performed by IEFA under the current link creation and viewing method. Therefore, the responsibility for the content of External Websites accessible via external links lies solely with their operators.`,
    'In addition, IEFA does not warrant that such External Web Sites or their contents do not violate any laws, including copyright, trademark or other third-party rights, or are free of viruses or other harmful components.',
    'No warranty is given as to the authenticity of the materials available on the Internet.',
    { type: 'title', text: 'SOCIAL MEDIA' },
    'The following terms apply when you access a Social Media page.',
    { type: 'subtitle', text: 'Déclaration générale' },
    'By using the Social Media pages and submitting a comment, photo, video or other material, you (and your parent/legal guardian if you are under the age of majority in the province or territory in which you reside) agree to be bound by and comply with the IEFA Legal Notice and the Terms of Use of third-party Social Media providers. You also consent to IEFA copying, editing, publishing, translating and distributing your comment, photo, video or other material posted on a Social Media without compensation.',
    { type: 'subtitle', text: 'Social Media Terms of Use' },
    'Some of the comments and other materials posted on the Social Media pages may not represent the views of IEFA. Therefore, you agree not to post or submit any information, posts, links or other materials of any kind to the Social Media pages that fall into any of the categories described below with respect to IEFA:',
    {
      type: 'list',
      items: [
        'Tout contenu diffamatoire, injurieux, contrefait, obscène, trompeur, illégal ;',
        'Tout contenu qui viole de toute autre façon les droits (y compris ceux liés à la protection des renseignements personnels) d’autrui ;',
        'Tout contenu comportant de la publicité ;',
        'Des documents promotionnels ou toute forme de sollicitations indésirables ;',
        'Tout contenu dont l’origine ou la source est falsifiée ;',
        'Tout renseignement financier ou personnel, à propos de vous ou d’une autre personne ; et',
        'Toute information que FEIA jugerait en contravention avec les Mentions légales.'
      ]
    },
    `Any information, postings, links or materials that IEFA deems to fall into any of the above categories will be removed immediately without liability to IEFA.`,
    'Furthermore, IEFA does not assume responsibility for screening messages posted on Social Media.',
    'IEFA disclaims all liability to entities that own or operate Social Media, including for any direct or indirect loss or damage resulting from any action or decision made by you or any other person in connection with the use of Social Media websites.',
    { type: 'title', text: 'DISCLAIMER, WARRANTY EXCLUSION AND INDEMNIFICATION' },
    'IEFA does not warrant the accuracy, completeness, quality, adequacy or content of the information on its Platform. This information is provided "as is" without warranty or condition of any kind, either express or implied, including, but not limited to, the implied conditions and warranties of merchantability and fitness for a particular purpose, except in jurisdictions that do not allow the exclusion of warranties.',
    'IEFA is not responsible for the content posted on the Platform by Users and for any damages that may result. Each User is responsible for the content he exchanges on the Platform and may not use the Platform for purposes contrary to the laws applicable in Quebec and Canada.',
    'Subject to applicable laws, IEFA shall not be liable for any direct, indirect, special, incidental or consequential damages, or any other damages whatsoever, including but not limited to loss of revenue, profit or anticipated economic benefits arising out of the use of or inability to use the Platform, the information, materials and software contained therein, even if IEFA has been advised of the possibility of such damages or even if such damages can be reasonably foreseen.',
    'You, as a User, will indemnify IEFA, and where applicable, its directors, officers, shareholders, partners, employees, agents, consultants and others involved with IEFA, and hold them harmless from and against any and all claims, liabilities, losses and expenses (including legal fees) arising out of your use of the Platform and/or your breach of these Terms of Use, whether such breach is by you or by any third party user of your account.',
    'You may not use or export the Information or any copy or adaptation of the Information in violation of any applicable laws or regulations.',

    { type: 'title', text: 'CHANGES TO THESE TERMS' },
    `We will occasionally update these Terms of Use. Continued use of the Platform constitutes the User's agreement to these Terms of Use and any updates.`,

    { type: 'title', text: 'INTERPRETATION' },
    'The headings of the foregoing sections are inserted for convenience of reference only and shall not affect the construction or interpretation of the provisions of these Terms of Use.',
    'Wherever the context requires, words importing the singular number shall include the plural and vice versa; words importing the masculine gender shall include the feminine gender and vice versa.',

    { type: 'title', text: 'CONTACT PERSON' },
    'If you have any questions or comments regarding any content on the Platform or Social Media, please contact us at admin@forum-americas.org.'
  ]
}
