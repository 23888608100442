export default {
  meta: {
    titleTemplate: 'IEFA TV'
  },
  about: 'About',
  brand: 'The International Economic Forum of the Americas',
  brandSmall: 'IEFA',
  live: 'Live',
  room: 'Room',
  floor: 'Floor language',
  navigation: {
    home: 'Home',
    events: 'Events',
    archives: 'Archives',
    about: 'About',
    aboutFEIA: 'About FEIA',
    contact: 'Contact us',
    faq: 'Help / FAQ',
    terms: 'Terms of use',
    privacy: 'Privacy policy'
  },
  cta: {
    learnMore: 'Learn more',
    close: 'Close',
    watchLive: 'Watch live on',
    watchNow: 'Watch now',
    liveOn: 'Live on'
  },
  languages: {
    title: 'Languages',
    french: 'Français',
    english: 'English',
    spanish: 'Español'
  },
  city: {
    montreal: 'Montreal',
    toronto: 'Toronto',
    paris: 'Paris',
    miami: 'Miami',
    buenosAires: 'Buenos Aires',
    phoenix: 'Phoenix'
  },
  video: {
    originalLanguage: 'Original language'
  },
  viewer: {
    publication: 'This video was published in',
    learnMore: 'To learn more about the event visit the',
    website: 'event website',
    overview: 'Conference Overview',
    about: 'About the event',
    program: 'Program',
    speakers: 'Speakers',
    partners: 'Partners'
  },
  form: {
    name: 'Name',
    email: 'Email',
    company: 'Company',
    phone: 'Phone number',
    subject: 'Subject',
    getTouch: 'Get in touch',
    partner: 'Become a partner',
    message: 'Message',
    button: 'Submit',
    tollFree: 'Toll free'
  },
  countdown: {
    countdownDays: 'day | days',
    countdownHours: 'hour | hours',
    countdownMinutes: 'minute | minutes',
    countdownSeconds: 'second | seconds'
  },
  copyright: 'The International Economic Forum of the Americas',
  cretedBy: 'Website created by',
  logo: '/images/brand/logo-en-white.png',
  logoSmall: '/images/brand/logo-small-en-white.png',
  previousConferences: 'Previous conferences',
  since: 'Since',
  watchLive: 'Watch live',
  nextEvent: 'Next event',
  in: 'In'
}
