export default {
  meta: {
    titleTemplate: 'FEIA TV'
  },
  about: 'À propos',
  brand: 'Forum économique international des Amériques',
  brandSmall: 'FEIA',
  live: 'En direct',
  room: 'Salle',
  floor: 'Langue originale',
  navigation: {
    home: 'Accueil',
    conferences: 'Conférences',
    archives: 'Archives',
    about: 'À propos',
    aboutFEIA: 'À propos du FEIA',
    contact: 'Nous contacter',
    faq: 'Aide / FAQ',
    terms: "Conditions d'utilisation",
    privacy: 'Politique de confidentialité'
  },
  cta: {
    learnMore: 'En savoir plus',
    close: 'Fermer',
    watchLive: 'Regarder en direct le',
    liveOn: 'En direct le',
    watchNow: 'Regarder en direct'
  },
  city: {
    montreal: 'Montréal',
    toronto: 'Toronto',
    paris: 'Paris',
    miami: 'Miami',
    buenosAires: 'Buenos Aires',
    phoenix: 'Phoenix'
  },
  languages: {
    title: 'Langues',
    french: 'Français',
    english: 'English',
    spanish: 'Español'
  },
  video: {
    originalLanguage: 'Langue originale',
    title: `Cooperation for a Sustainable World (EN)`,
    subtitle: `Latam Forum — 2ème édition`,
    text: 'Our next event to air on IEFA.TV is the IEFA Latam Forum, streaming live from Buenos Aires on March 26, 2024, 08:45-18:30 local time (ART). To view the full program and speaker line-up for this event, please visit the IEFA Latam Forum website. '
  },
  viewer: {
    publication: 'Cette vidéo a été publiée en',
    learnMore: "Pour en savoir plus sur l'événement, visitez le",
    website: 'site web',
    overview: 'Aperçu de la conférence',
    about: "À propos de l'événement",
    program: 'Programme',
    speakers: 'Conférenciers',
    partners: 'Partenaires'
  },
  form: {
    name: 'Nom',
    email: 'Courriel',
    company: 'Entreprise',
    phone: 'Téléphone',
    subject: 'Sujet',
    getTouch: 'Prendre contact',
    partner: 'Devenir un partenaire',
    message: 'Message',
    button: 'Envoyer',
    tollFree: 'Sans frais'
  },
  countdown: {
    countdownDays: 'jour | jours',
    countdownHours: 'heure | heures',
    countdownMinutes: 'minute | minutes',
    countdownSeconds: 'seconde | secondes'
  },
  copyright: 'Forum économique international des Amériques',
  cretedBy: 'Site web créé par',
  logo: '/images/brand/logo-fr-white.png',
  logoSmall: '/images/brand/logo-small-fr-white.png',
  previousConferences: 'Conférences précédentes',
  since: 'Depuis',
  watchLive: 'Regarder en direct',
  nextEvent: 'Prochain événement',
  in: 'Dans'
}
