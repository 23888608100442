import Vue from 'vue'

export const EventBusVm = new Vue({
  name: 'EventBusVm',
  data() {
    return { contentLoaded: false }
  }
})

export const eventBus = {
  on(name, handler) {
    EventBusVm.$on(name, handler)
  },
  once(name, handler) {
    EventBusVm.$once(name, handler)
  },
  off(name, handler) {
    EventBusVm.$off(name, handler)
  },
  emit(name, ...payload) {
    EventBusVm.$emit(name, ...payload)
  },
  get contentLoaded() {
    return EventBusVm.contentLoaded
  }
}

export default () => {
  Vue.prototype.$bus = eventBus
}
