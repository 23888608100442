<template>
  <article class="page-header" :style="{ '--image': `url('/images/events/${slug}/background-small.png')` }">
    <LocaleLink :to="{ name: 'conference' }" class="page-header__toptitle -back">
      <svg-icon id="arrow" />
      {{ conference.title }}
    </LocaleLink>

    <h1 class="page-header__title">{{ event.title }}</h1>
  </article>
</template>

<script>
export default {
  name: 'StreamingHeader',
  props: {
    slug: { type: String, required: true },
    year: { type: String, required: true },
    conference: { type: Object, required: true },
    event: { type: Object, required: true }
  }
}
</script>
