<template>
  <footer class="navigation-footer">
    <section v-appear="{ threshold: 0 }" class="navigation-footer__container">
      <figure class="navigation-footer__shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="348"
          height="226.232"
          viewBox="0 0 348 226.232"
        >
          <path
            d="M13.494,179.507l1.493-41.137,135.2,74.922L176,75.342l104.3,128.85L327.025,45.537"
            transform="translate(-0.927 -0.765)"
            stroke-linejoin="round"
            stroke-width="27"
          />
        </svg>
      </figure>

      <article class="navigation-footer__content">
        <ul v-appear="{ delay: 200 }" class="navigation-footer__list">
          <li class="navigation-footer__item">
            <span class="navigation-footer__label">{{ $t('common.form.email') }} :</span>
            <span class="navigation-footer__link">info@forum-americas.org</span>
          </li>

          <li class="navigation-footer__item">
            <span class="navigation-footer__label">{{ $t('common.form.phone') }} :</span>
            <span class="navigation-footer__link">+1 514 871-2225</span>
          </li>

          <li class="navigation-footer__item">
            <span class="navigation-footer__label">{{ $t('common.form.tollFree') }} :</span>
            <span class="navigation-footer__link">+1 866 865-2225</span>
          </li>
        </ul>

        <!-- <ul v-appear="{ delay: 300 }" class="navigation-footer__list">
          <LocaleLink :to="{ name: 'home', hash: '#contact' }" class="navigation-footer__item -link">
            {{ $t('common.cta.contact') }}
          </LocaleLink>
          <a href="https://iefa-plus.com/" target="_blank" class="navigation-footer__item -link">
            {{ $t('common.navigation.consulting') }}
          </a>
        </ul> -->

        <ul v-appear="{ delay: 300 }" class="navigation-footer__list">
          <LocaleLink :to="{ name: 'faq' }" class="navigation-footer__item -link">
            {{ $t('common.navigation.faq') }}
          </LocaleLink>

          <LocaleLink :to="{ name: 'contact' }" class="navigation-footer__item -link">
            {{ $t('common.navigation.contact') }}
          </LocaleLink>

          <a href="https://forum-ameriques.org/" target="_blank" class="navigation-footer__item -link">
            {{ $t('common.navigation.aboutFEIA') }}
          </a>
        </ul>

        <ul v-appear="{ delay: 400 }" class="navigation-footer__list">
          <a href="https://forum-ameriques.org/terms-of-use" target="_blank" class="navigation-footer__item -link">
            {{ $t('common.navigation.terms') }}
          </a>

          <a href="https://forum-ameriques.org/privacy-policy" target="_blank" class="navigation-footer__item -link">
            {{ $t('common.navigation.privacy') }}
          </a>
        </ul>
      </article>

      <article class="navigation-footer__copyright">
        <div v-appear="{ delay: 350, offset: '0px', threshold: 0 }" class="navigation-footer__copyright-flex">
          <p>Copyright &copy; {{ $t('common.copyright') }} {{ currentYear }}</p>
          <span>|</span>
          <p>
            {{ $t('common.cretedBy') }}
            <a href="https://medioze.com/" target="_blank">Medioze</a>
          </p>
        </div>

        <div class="navigation-footer__socials">
          <a
            v-appear="{ delay: 400, offset: '0px', threshold: 0 }"
            href="https://www.facebook.com/Forum.Americas/"
            target="_blank"
            rel="noopener"
            class="navigation-footer__icon"
          >
            <svg-icon id="social-facebook" />
          </a>

          <a
            v-appear="{ delay: 425, offset: '0px', threshold: 0 }"
            href="https://twitter.com/AmericasForum"
            target="_blank"
            rel="noopener"
            class="navigation-footer__icon"
          >
            <svg-icon id="social-twitter" />
          </a>

          <a
            v-appear="{ delay: 475, offset: '0px', threshold: 0 }"
            href="https://www.linkedin.com/company/1162497/"
            target="_blank"
            rel="noopener"
            class="navigation-footer__icon"
          >
            <svg-icon id="social-linkedin" />
          </a>

          <a
            v-appear="{ delay: 500, offset: '0px', threshold: 0 }"
            href="https://www.youtube.com/channel/UCquT5NzE7P8_wP7m2tnbRjQ"
            target="_blank"
            rel="noopener"
            class="navigation-footer__icon"
          >
            <svg-icon id="social-youtube" />
          </a>

          <a
            v-appear="{ delay: 525, offset: '0px', threshold: 0 }"
            href="https://www.instagram.com/forumamericas/?ref=badge"
            target="_blank"
            rel="noopener"
            class="navigation-footer__icon"
          >
            <svg-icon id="social-instagram" />
          </a>
        </div>
      </article>
    </section>
  </footer>
</template>

<script>
export default {
  name: 'NavigationFooter',
  data() {
    return {
      currentYear: new Date().getFullYear(),
      links: [
        { to: { name: 'home', hash: '#events' }, label: 'Events' },
        { to: { name: 'home', hash: '#about' }, label: 'About us' },
        { to: { name: 'home', hash: '#services' }, label: 'Services' }
      ]
    }
  }
}
</script>
