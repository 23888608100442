export default {
  title: 'PRIVACY POLICY',
  update: 'Last update on May 15, 2023.',
  meta: {
    title: 'Privacy policy'
  },
  wp: 'false',
  content_static: [
    { type: 'title', text: 'INTRODUCTION ' },
    'In the face of new challenges and dangers in maintaining the security of personal information arising from online business, it is necessary to pay particular attention to privacy. That is why we the International Economic Forum of the Americas Inc. (hereinafter the "<strong>IEFA</strong>") is seriously concerned about the privacy of your personal information and is committed to respecting the confidentiality of the information we collect.',
    'IEFA is committed to respecting, in accordance with this Policy (hereinafter the "Policy") and applicable laws in Canada and Quebec, the obligations regarding the collection and processing of your personal and confidential information, obtained in the course of operating its business, so that everyone can benefit from the respect of their privacy.',
    'In this context, IEFA wishes to ensure the accuracy, security and confidentiality of your personal information, and not to collect, use or disclose your personal information other than in accordance with this Policy and such applicable laws.',
    'The Policy describes the information IEFA may collect in the course of its business from its Users.',
    'The Policy also describes, among other things, how IEFA collects, processes, maintains and uses your information and the circumstances under which it may be transferred to third parties. The Policy explains your rights regarding the collection, use and disclosure of your personal information.',
    { type: 'title', text: 'SCOPE OF THE POLICY ' },
    'We collect your personal information as part of our mission to organize events during which heads of state and government, members of the private sector and civil society, trade union and academic leaders discuss major international economic issues. Information and content relating to these events is available on our Platform.',
    'By browsing the Platform or subscribing to our publications by email, the User agrees to the terms and conditions of this Policy. ',
    'You agree that your personal information collected when filling out any online form for the purpose of, among other things, making contact, uploading content to the Platform, or rendering other product-related services, will be transmitted to appropriate and authorized IEFA personnel. IEFA will only disclose to third parties whose involvement is strictly necessary to deliver the Tickets, Events and/or to maintain our business relationship with you, if any, the information necessary for these purposes (the aforementioned third parties may hereinafter be collectively referred to as "Third-Party Providers").',
    'By providing IEFA with your personal information, you authorize IEFA to use and disclose such information to Third Party Providers, if any, in connection with the delivery of products, the administration of our contractual relationship, our business relationship with you, and in accordance with this Policy and as permitted or required by law, as well as implicitly, if the circumstances so indicate.',
    'In addition, IEFA will seek your permission before using your personal information for purposes other than those for which you originally consented under the Policy.',
    'Finally, you may withdraw your consent at any time, subject to legal and contractual restrictions and reasonable notice. Please be aware, however, that without your consent, IEFA may not be able to provide or continue to provide you with some of the services or information that may be useful to you.',
    'If you provide IEFA or its Third-Party Providers with personal information about another individual or entity, you agree that you have the necessary authorization to do so and/or that you have obtained all necessary consents from that third party to allow us to collect, use and disclose their personal information for the purposes set out in this Policy.',
    'To withdraw your consent or to stop receiving electronic communications from us, please write to the following email address: admin@forum-americas.org.',
    { type: 'title', text: 'COLLECTION OF PERSONAL INFORMATION' },
    "Personal information is information that is specifically associated with an individual and can be used to identify that individual, either on its own or in combination with other information that IEFA may have access to. Information that has been anonymized or that has been arranged so that it can no longer be used to identify a specific individual is not personal information. For example, an individual's office mailing address is not personal information, except when it is listed with other information about the individual, or when its mere listing would reveal personal information about the individual.",
    'IEFA may obtain personal information from the User when the User saves personal information on the Platform or, where applicable, by otherwise providing personal information, including by creating a User account and choosing a password, by logging in as a registered User to the Platform, conducting a product search, requesting a service, providing account information, contacting us by phone, email or otherwise, or filling out a form to participate in an event, contest, inventory request, promotion or survey. By filling out such a form, you are giving us permission to collect your personal information.',
    'We collect the following information from the User upon registration: ',
    {
      type: 'list',
      items: [
        'Full name;',
        'Telephone number;',
        'Personal address;',
        'Email address;',
        'Company name; and',
        'Title within the company.'
      ]
    },
    'In general, we may also collect your location, IP address and data about your use of the Platform and any other information that will enable us to provide you with our products in the most appropriate way.',
    'User information is also collected through the interaction that may take place between the User and the Platform and may be used for the following purposes:',
    {
      type: 'list',
      items: [
        'To understand how the Platform is used by the User;',
        'To improve the Platform;',
        'To provide services to the User;',
        'To manage customer accounts;',
        'To manage customer and business relationships; and',
        'To provide personal information to business partners;',
        'To comply with compliance laws and regulations;',
        'To send newsletters and;',
        "To send promotions tailored to User's needs."
      ]
    },
    "The User's personal information will not be retained beyond the purposes for which it was collected. IEFA respects the retention period established by this Policy, which is in compliance with the Privacy Act. Data deletion occurs when the User deletes his/her account. The User will then have thirty (30) days to retrieve his/her data, failing which IEFA will permanently delete it.",
    'IEFA may also compile lists of your names and mailing addresses for philanthropic, anonymized statistical or market research purposes. If you do not wish to have your names and mailing addresses compiled for these purposes, please contact us at admin@forum-americas.org.',
    "The deletion of personal information occurs upon written request by the User to the above address. IEFA reserves the right to refuse a User's request where the User's continued use of IEFA services in any way complies with the retention period established by this Policy, which is consistent with the Private Sector Privacy Act. Accordingly, and except as otherwise provided by the regulatory framework, deletion of data will be effective thirty (30) days after the Privacy Officer has processed the request for deletion of personal information.",
    'The Platform is designed for use by adults only as understood in the province of Quebec. IEFA does not knowingly collect personal information from persons under the age of eighteen (18). The Platform is not designed for persons under the age of eighteen (18).',
    "For more information on the protection of your personal information and your rights, you may contact the Office of the Privacy Commissioner of Canada or the Commission d'accès à l'information du Québec.",
    {
      type: 'list',
      items: [
        'Office of the Privacy Commissioner of Canada www.privcom.gc.ca',
        "Commission d'accès à l'information du Québec www.cai.gouv.qc.ca"
      ]
    },
    { type: 'title', text: 'FORMS AND INTERACTIONS' },
    'IEFA collects your personal information in accordance with legal requirements. Wherever possible and appropriate, we collect your personal information directly from you or from the individuals you have chosen to provide your personal information to us.',
    'User’s personal information may be collected through forms, namely:',
    {
      type: 'list',
      items: [
        'Platform registration form ;',
        'Order form ;',
        'User profile update form ;',
        'User access authorization to their information ;',
        'Contact form ;',
        'Support form ;'
      ]
    },
    'Your basic personal information, such as your email, last name, first name, and phone number, may also be collected on our Platform, including via email.',
    { type: 'title', text: 'ADVERTISING INFORMATION' },
    'IEFA will only contact you if you choose to be contacted.',
    'If the User does not subscribe to our newsletter, does not have a business relationship with IEFA or has never attended one of our events, he will not receive any emails from IEFA without prior consent.',
    'If you prefer not to receive marketing information from us or if you do not want us to use your personal information to enhance your User experience, please let us know by contacting us at the contact information listed in the "Right of objection and withdrawal" section below.',
    'Please remember to include your full name and email address that you used when you registered or made a purchase from us. There may be a delay in processing an email or postal mail request and you may continue to receive communications from IEFA in the interim.',
    { type: 'title', text: 'ADVERTISING INFORMATION' },
    'ANTI-SPAM LAWS',
    'IEFA complies with the requirements of the Canadian Anti-Spam Act (CASL).',
    'The User understands that he may expressly or impliedly consent to receive messages from IEFA.',
    'Express consent is consent sought from the User, which consent shall be stated in plain language, and shall include the purpose for which consent is sought, prescribed information identifying the person seeking consent and, if sought on behalf of another person, prescribed information identifying that person; and any other information specified by regulations made under this Act.',
    'The User understands that he or she is giving implied consent in the case of ongoing business or private relationships; when the User publishes his or her e-mail address without indicating that he or she does not wish to receive unsolicited commercial e-mail messages; or when the message is sent in other circumstances specified by regulations made under this Act.',
    {
      type: 'list',
      items: [
        'For the purposes of this Policy, a current business relationship refers to a business relationship between two persons such as a purchase or a participation in one of the events organized by IEFA within two years prior to the date the message is sent; an investment or service; the bartering of a thing; any contract in effect or expired during that period; or an application within the six months prior to the date the message is sent.',
        'For the purposes of this Policy, a current private relationship means a relationship between two persons other than a business relationship, such as a gift or donation, volunteer work; and a membership, as defined in the bylaws; all within the two months preceding the date the message is sent.'
      ]
    },
    'To unsubscribe from our electronic communications mailing list, simply contact us at admin@forum-americas.org.',
    { type: 'title', text: 'PROTECTION AGAINST FRAUD AND THEFT OF YOUR PERSONAL INFORMATION' },
    'IEFA is committed at all times to complying with the laws and regulations applicable to its business and to taking all useful and necessary measures to detect and prevent negligence, fraud and theft of your personal information. However, while IEFA takes precautions, IEFA cannot guarantee that no instances of fraud or theft of your personal information will occur.',
    'IEFA maintains an up-to-date privacy incident log and will contact any User whose personal information is the subject of such an incident and presents a risk of serious harm.',
    { type: 'title', text: 'THIRD PARTY ADVERTISING DISPLAYED ON OUR PLATFORM' },
    'You may find third-party advertising displayed on our Platform. We do not allow third parties to collect your personal information on our Platform without your consent.',
    'IEFA prohibits and is not responsible for the resale or use by third parties of opt-ins obtained through the Platform by such third parties. If you have agreed to share your contact information with partner organizations, then we will share it with them.',
    'We do not sell or rent your personal information without your consent.',
    { type: 'title', text: 'THIRD-PARTY SERVICES' },
    'IEFA may use, as part of its service delivery and/or management of its business and Platform, third party services. In no event shall IEFA be liable for any damages arising from such third-party services.',
    'To know the conditions of use of these services, we invite the User to consult their respective websites.',
    { type: 'title', text: 'DATA EXCHANGED AUTOMATICALLY' },
    "Through the use of cookies, we collect certain information during exchanges between your computer and our server. Cookies are files created by a website that record information about your computer's navigation of the site. They are stored automatically on your electronic devices, allow you to log in to the Platform at a later time and allow IEFA to personalize the Platform.",
    'This information includes, but is not limited to, the following:',
    {
      type: 'list',
      items: ['Domain name ;', 'IP address ;', 'Browsing history ;', 'User’s location ; and', "User's language."]
    },
    'Most of the information automatically transmitted does not identify the User. It is collected only as a result of technological requirements inherent in Internet browsing. Most browsers automatically accept cookies, but it is usually possible to change this behavior. However, if the User chooses to decline cookies, he or she may not be able to log in or use certain features of the Platform.',
    'The use of such information allows us to improve the service, personalize the User experience, and better track your past usage.',
    'In addition to the methods described above, we may also collect information through the use of Cookies or similar technologies, particularly during your visits to our Platform. These methods allow us to collect various types of information, including the pages you visit, the emails you read, and other information.',
    'This information is also used to:',
    {
      type: 'list',
      items: [
        'To identify the User;',
        'To register the User, in particular for an event;',
        "To process the User's requests and orders and answer their questions;",
        'To keep in touch with the User and provide them with a personalized service at all times;',
        'To communicate with the User (by email, mail or otherwise) and send advertising materials or additional information about products, services, surveys, contests, promotions and special offers ;',
        'For analytical and statistical purposes;',
        "To learn more about the User's purchasing preferences;",
        'To develop new products and services; and',
        'To conduct market research and performance research to evaluate customer service, measure performance, enhance the User experience and improve products.'
      ]
    },
    'These Cookies are multiple, some of them expire when the User closes his browser and have no more effect while others can be kept. At any time, the User may deactivate the Cookies. In this case, IEFA does not guarantee an optimal functioning of the Platform.',
    'It is also possible to prevent the storage of Cookies by configuring your Internet browser. However, if you choose the latter option, you may not be able to take advantage of the entire Platform.',
    { type: 'title', text: 'INTERNATIONAL COMMUNICATIONS' },
    'In some situations, User data may be transmitted to servers outside of Canada. IEFA may also share your personal information with third parties outside of Quebec, notably in the European Union, and held by companies located in the above-mentioned territories and subject to their legislation.',
    'The User acknowledges and releases IEFA from any liability for the consequences of such a transfer.',
    'IEFA will not share your personal information with third parties located in jurisdictions that do not provide a similar level of privacy protection as Quebec.',
    { type: 'title', text: 'SHARING OF PERSONAL INFORMATION' },
    'IEFA may share personal information collected from the User in certain circumstances. The situations in which personal information may be shared are: sharing with company partners, sharing with sponsors and/or advertisers and the application of the law or legal requests.',
    'The User understands that IEFA may at any time share personal information in the event of a partial or total transfer of the business to a third party. In such a case, the information transferred as an asset of IEFA will be subject to the same safeguards as this Policy.',
    'IEFA may also publicly re-post or re-share personally identifiable information when you post content related to IEFA on Social Media. In addition, by clicking on an external link located on the Platform, you may be accessing external websites that have their own privacy policies. You are advised to consult them, as you will be subject to them.',
    'If the User does not wish to have his/her personal information shared with third parties or partners, he can object at any time, as mentioned in the following "Right to object and withdraw" section.',
    "IEFA may, on an exceptional basis, disclose the User's personal information without the User's consent in the following circumstances:",
    {
      type: 'list',
      items: [
        "When legally required to do so, such as when a court order requires IEFA to disclose the User's personal information.",
        "When consent cannot be obtained and privacy laws permit IEFA to disclose the User's personal information. This is particularly the case when the protection of the public interest so requires."
      ]
    },
    { type: 'title', text: 'RIGHT OF OBJECTION AND WITHDRAWAL' },
    'We are committed to providing the User with the right to object to and withdraw their personal information.',
    'The right to object is understood to be the possibility offered to Internet users to refuse that their personal information be used for certain purposes mentioned at the time of collection.',
    'The right to opt-out is defined as the possibility for Internet users to request that their personal information no longer appears, for example, on a mailing list.',
    'In accordance with the following section, the User also has the right to request access to and correction of the personal information we have collected about him or her, and to exercise his or her right to object or opt-out, by contacting the Privacy Officer, whose contact information can be found in the "Contact Us" section below.',
    { type: 'title', text: 'ACCESS TO YOUR PERSONAL INFORMATION' },
    'Other than as set forth in this Policy, your personal information collected by IEFA hereunder will be accessible only by authorized and appropriate employees of IEFA.',
    'IEFA, subject to certain exceptions, may inform you of your personal information, how it is used and whether it has been shared with third party service providers. You will also be able to access this information for any amendment or deletion.',
    'Your request for access must be made in writing and sent in accordance with the procedures set forth in this section and in the "Contact Us" section below.',
    'IEFA will acknowledge your request in writing and may ask you to confirm your identity before providing you with this information. Access to this information will normally be provided free of charge, but if you request a copy or transmission of your information, we may charge a reasonable fee. IEFA will inform you of the amount in advance and give you the opportunity to withdraw your request. IEFA will provide you with access to your information within thirty (30) days of receiving your request or will provide you with written notice that additional time is required to fulfill your request.',
    'In some cases, we may be unable to provide you with access to all or part of your information. If we are unable to do so, or if the law does not permit us to do so, we will provide you with an explanation and indicate what other steps are available to you.',
    { type: 'title', text: 'SECURITY ' },
    'The personal information IEFA collects is kept in a secure environment. IEFA has adopted appropriate measures to maintain the confidentiality of personal information and to protect it from loss or theft, as well as from unauthorized access, disclosure, copying, use or modification, taking into account, among other things, the sensitivity of the information and the purposes for which it is used. In addition, IEFA requires that each employee, agent or representative of IEFA comply with this Policy.',
    "To ensure the security of the User's personal information, IEFA uses, among others, the following measures:",
    {
      type: 'list',
      items: [
        'Secure Sockets Layers (SSL);',
        'Secure access to premises;',
        'Secure access to servers and computers;',
        'Firewalls;',
        'Use of password protected data networks;',
        'Confidentiality agreements with employees;',
        'Internal privacy policies;',
        'Privacy procedures;',
        'Routine updating of security measures; and',
        'Encrypted and secure electronic communications.'
      ]
    },
    "IEFA is committed to maintaining a high level of security by incorporating the latest technological innovations to ensure the confidentiality of the User's transactions in order to ensure that their personal data is protected from unauthorized access, use or disclosure. IEFA is committed to keeping a record of privacy incidents.",
    "However, since no mechanism offers maximum security, there is always some degree of risk involved in using the Internet to transmit personal information. If a password is used to protect the User's account and personal information, it is the User's responsibility to ensure that it is kept confidential.",
    "In the event of a privacy incident involving a User's personal information held by IEFA, IEFA will notify the affected User and the Commission d'accès à l'information du Québec of any privacy incident that presents a risk of serious harm.",
    { type: 'title', text: 'FRENCH AND ENGLISH LANGUAGE ' },
    'If a problem of definition or interpretation arises between the English and French versions of this Policy, the French version shall prevail.',
    { type: 'title', text: 'APPLICABLE LAW AND JURISDICTION' },
    "The Platform and its content are operated by IEFA from its offices located in the province of Quebec, Canada. The Platform, the Social Media and its content, this Legal Notice and the User's use of it are all governed by the laws of Quebec and Canada which are applicable, without giving effect to any principles of conflicts of law.",
    'You agree that all questions and disputes regarding the Platform and Social Media and the use thereof shall be first mediated and, in the absence of compromise, decided by the courts of Montreal.',
    { type: 'title', text: 'CONTACT' },
    'If you have any questions about the processing or updating of your personal information or if you have any concerns in this regard, please contact Nicholas Rémillard, the Privacy Officer, at the following e-mail address: admin@forum-americas.org. The Privacy Officer will contact you within thirty (30) days of receiving your email.',
    { type: 'title', text: 'INTERPRETATION' },
    'This Policy is governed by the applicable laws of Quebec and Canada, including the Private Sector Privacy Act of Quebec. These laws apply without giving effect to any principles of conflict of laws.',
    'Wherever the context requires, words importing the singular number shall include the plural and vice versa; words importing the masculine gender shall include the feminine gender and vice versa.',
    { type: 'title', text: 'DIVISIBILITY' },
    'Each provision of this Policy forms a separate and distinct whole so that any determination by a court that any provision of this Policy is void or unenforceable shall not affect the validity or enforceability of any other provision of this Policy.',
    { type: 'title', text: 'CHANGES TO THIS POLICY ' },
    "We will occasionally update this Policy. Continued use of the Platform constitutes User's agreement to this Policy and any updates.",
    "Wherever possible, we will attempt to inform Users of any changes to the Policy. However, it is the User's responsibility to review the Policy regularly to be aware of any changes."
  ]
}
