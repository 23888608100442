<template>
  <section>
    <HomeLive v-if="$api.liveEvent" :event="$api.liveEvent" />
    <HomeHeader v-else />
    <!-- <HomePublicity /> -->
    <HomeConference />
    <HomeAbout />
  </section>
</template>

<script>
import * as partials from './partials'

export default {
  name: 'Home',
  components: { ...partials }
}
</script>
