export default {
  title: 'Termes & Conditions',
  update: 'Dernière mise à jour effectuée le 15 mai 2023.',
  meta: {
    title: "Conditions d'utilisation"
  },
  wp: 'false',
  content_static: [
    `Veuillez lire attentivement les Conditions d’utilisation (ci-après les « <strong>Conditions d’utilisation</strong> ») ci-dessous de Forum économique international des Amériques Inc., société dont le siège social est situé au 620-606 rue Cathcart (H3B1K9), dans la ville de Montréal, province du Québec, au Canada (ci-après « <strong>FEIA</strong> »).`,
    `FEIA opère le présent site web (ci-après « <strong>Plateforme</strong> ») et les pages de média social, compte, site internet, réseau ou toute application contenue dans ceux-ci conçues et gérées par le FEIA (ci-après le « <strong>Média social</strong> » ou les « <strong>Médias sociaux</strong> »).`,
    `En décidant d’utiliser la Plateforme et les services du FEIA, l’utilisateur, entendu comme l’utilisateur final ou l’organisation que vous représentez ou pour laquelle vous travaillez (ci-après l’« <strong>Utilisateur</strong> » ou les « <strong>Utilisateurs</strong> ») accepte toutes les Conditions d’utilisation indiquées ci-dessous qui régissent le contenu et l’utilisation de la Plateforme, ainsi que la politique de protection des renseignements personnels figurant à la suite des Conditions d’utilisation du FEIA. `,
    `Ces Conditions d’utilisation ont pour effet de lier juridiquement l’Utilisateur et le FEIA (ci-après les « <strong>Parties</strong> »). Les Parties sont en droit d’invoquer les présentes Conditions d’utilisation et de prendre toutes les mesures utiles et nécessaires pour les faire respecter.`,
    `Si l’Utilisateur n’accepte pas les présentes Conditions d’utilisation ou, selon le cas, les Conditions d’utilisation telles que modifiées de temps à autres, il ne doit pas, ou ne doit plus, utiliser la Plateforme. `,
    `Les termes définis dans les présentes Conditions d’utilisation sont utilisés dans la politique de protection des renseignements personnels et les conditions générales de vente (les Conditions d’utilisation, la politique de protection des renseignements personnels et les conditions générales de vente sont ci-après désignées les « <strong>Mentions légales</strong> ») du FEIA. Nous vous recommandons d’en prendre connaissance.`,
    { type: 'title', text: 'ACCÈS ET UTILISATION DE LA PLATEFORME' },
    'Si vous naviguez sur la Plateforme, cela signifie que vous avez lu, compris et accepté les Conditions d’utilisation.',
    'La Plateforme est accessible depuis n’importe quel outil informatique (ordinateur, cellulaire intelligent, tablette, etc.) et sur tous les navigateurs.',
    'Pour une utilisation optimale, nous préconisons l’utilisation des navigateurs suivants : Google Chrome, Firefox, Safari, Microsoft Edge.',
    'La Plateforme est destinée à un public professionnel. La Plateforme n’est pas destinée à un public de consommateurs. En acceptant les Conditions d’utilisation, vous déclarez et garantissez que vous le faites à titre de professionnel ou commercial. En utilisant la Plateforme, vous garantissez que vous le faites à titre professionnel ou commercial plutôt qu’à titre de consommateur ou pour des fins personnelles.',
    { type: 'title', text: 'LE SERVICE' },
    'La Plateforme est accessible en tout temps. Il peut arriver que la Plateforme soit temporairement indisponible en cas de mises à jour ou de problèmes techniques. Le FEIA n’est pas responsable en cas d’interruption de service ou de mise à jour. Le FEIA essaie d’effectuer les mises à jour lorsque l’affluence des visites sur la Plateforme est à son plus bas.',
    'Le FEIA ne garantit pas que la Plateforme, ou toute partie de celle-ci, fonctionnera sur du matériel informatique ou des périphériques particuliers. Par ailleurs, la Plateforme peut être sujette à des dysfonctionnements et à des retards inhérents à l’utilisation d’Internet et des communications électroniques.',
    'Le FEIA se réserve le droit d’ajouter, de supprimer et de modifier tout le contenu, ou une partie de celui-ci, sur la Plateforme, à l’exception du contenu propre à l’Utilisateur. FEIA ne peut pas être tenue responsable des fautes, des erreurs typographiques et des bogues. Chaque Utilisateur créé dans le système est propriétaire du contenu de son compte. Le FEIA ne supprime rien à moins qu’un Utilisateur n’en fasse la demande.',
    'Chaque Utilisateur de la Plateforme s’engage, non limitativement, à ne pas : ',
    {
      type: 'list',
      items: [
        'Utiliser la Plateforme d`une façon qui pourrait endommager, mettre hors service, surcharger ou compromettre la Plateforme ;',
        'Perturber la sécurité ou autrement abuser de la Plateforme, ni d`aucun service, ressource des systèmes, serveurs ou réseaux reliés à la Plateforme ou à des sites affiliés ou reliés ou accessibles à travers eux ;',
        "Déranger ou perturber l'utilisation ou la jouissance de la Plateforme par d`autres services ou des sites affiliés ou reliés ;",
        'Télécharger, afficher ou autrement transmettre sur la Plateforme un virus ou autre fichier ou programme informatique nuisible, dérangeant ou destructeur ;',
        'Utiliser un robot, araignée ou autre dispositif automatique ou procédé manuel afin de contrôler ou copier les pages de la Plateforme ou son contenu ;',
        'Transmettre à travers la Plateforme des courriels, des chaînes de lettres, des messages pourriels ou autres types de courriels de masse non sollicités ; et/ou',
        "Tenter d'obtenir un accès non autorisé à la Plateforme ou à certaines portions de celle-ci auxquels l`accès est limité. De plus, l’Utilisateur convient qu’il est le seul responsable pour toutes actions et communications entreprises ou transmises à ou pour son compte, et il convient de respecter toutes les lois applicables quant à son utilisation ou ses activités sur la Plateforme."
      ]
    },
    { type: 'title', text: 'PROPRIÉTÉ INTELLECTUELLE' },
    `À moins d’indication contraire, tous les droits de propriété intellectuelle (marque de commerce, droit d’auteur, etc.) (ci-après la « <strong>Propriété intellectuelle</strong> ») liés à la Plateforme sont la propriété exclusive du FEIA. Vous ne pouvez ni copier ni utiliser le matériel, la structure, le menu, l’apparence ou les caractères qui apparaissent sur la Plateforme sans le consentement explicite du FEIA.`,
    `Certains noms, mots, titres, expressions, logos, icônes, graphiques, dessins, noms de domaine ou autre, contenus sur la Plateforme ou les Médias sociaux peuvent entre autres être des marques de commerce ou des appellations commerciales, déposées ou non déposées (aux fins de ces Conditions d’utilisation, elles sont toutes désignées par le terme « <strong>Marques de commerce</strong> »). Ces Marques de commerce sont protégées par les lois canadiennes et étrangères applicables aux marques de commerce et sont, selon le cas, la propriété exclusive du FEIA.`,
    `Sans en limiter la portée, les éléments de Propriété intellectuelle appartenant au FEIA incluent, non exhaustivement, les éléments suivants : `,
    {
      type: 'list',
      items: [
        'Tous les logos, dessins, graphismes, photos, vidéos, marques (déposées ou non), symboles, textes, expressions, mots ainsi que tout le contenu général de la Plateforme ou les Médias sociaux n’étant pas déjà soumis à des droits de Propriété intellectuelle ;',
        'Tout le contenu proposé par la Plateforme ou les Médias sociaux en libre téléchargement.'
      ]
    },
    "À moins d’indication contraire, tous les renseignements fournis sur la Plateforme ou les Médias sociaux appartiennent au FEIA, et sont protégés par les lois applicables en l’espèce. De plus, l'information ne peut autrement être copiée, affichée, distribuée, téléchargée, exploitée sans licence, modifiée, publiée, reproduite, réutilisée, vendue, transmise ou utilisée à des fins d'œuvres dérivées, publiques ou commerciales sans une autorisation écrite et expresse de FEIA. L'information est protégée en vertu de la législation sur le droit d'auteur et les marques de commerce du Canada et des pays qui hébergent la Plateforme ou les Médias sociaux.",
    "Vous pouvez utiliser l'information exclusivement à des fins personnelles sans modification, sous réserve que vous référenciez sur les copies les mentions concernant les droits d'auteur, les avis et attributions de marques de commerce respectifs. Les présentes Conditions d’utilisation vous accordent une autorisation en ce qui a trait à la consultation et à l’affichage sur votre ordinateur, et ce, uniquement à des fins non commerciales, personnelles et éducatives. Cette autorisation n’appartient qu’à vous ; elle ne peut être attribuée ou transférée à une autre personne, ou faire l’objet d’une sous-licence.",
    'Vous n’êtes pas autorisé à télécharger, copier, enregistrer ou imprimer le contenu de la Plateforme ou des Médias Sociaux. Pour obtenir de plus amples renseignements concernant l’autorisation d’utiliser, de reproduire ou de publier à nouveau toute information présentée sur la Plateforme ou sur les Médias sociaux, l’Utilisateur peut nous contacter à admin@forum-americas.org. Les images ou le texte contenus sur la Plateforme ou les Médias sociaux qui sont téléchargés, copiés, enregistrés ou imprimés sont encore soumis à ces Conditions d’utilisation. L’Utilisateur doit assurer que tous les avis concernant les droits d’auteur et les marques y figurent toujours quelconque contenu présenté sur la Plateforme ou les Médias sociaux.',
    "Tout téléchargement, retransmission, copie ou modification non autorisés de la Propriété Intellectuelle ou des données contenues sur la Plateforme ou les Médias sociaux peut contrevenir aux lois incluant celles relatives aux marques de commerce ou aux droits d'auteur, et pourrait exposer l'Utilisateur à des poursuites judiciaires.",
    'Le contenu de la Plateforme ou les Médias sociaux peut être modifié en tout temps sans préavis. Pour obtenir de plus amples renseignements concernant l’interdiction d’utiliser, de reproduire ou de publier à nouveau toute information présentée sur la Plateforme, veuillez nous contacter à admin@forum-americas.org.',
    "Nulle disposition des présentes ne doit être interprétée comme conférant une licence ou un droit quelconque à l’Utilisateur en vertu des lois concernant les droits d'auteur ou les marques de commerce.",
    'Sous réserve des lois applicables, l’importation, la création, la soumission ou le stockage de contenu par l’Utilisateur confère à FEIA une licence non-exclusive gratuite, cessible, sous-licenciable, et ce dans le monde entier. Cette licence autorise FEIA à héberger, utiliser, modifier, reproduire, publier, représenter publiquement, créer des œuvres dérivées, ou encore distribuer votre contenu. En vertu de cette licence, FEIA ne peut utiliser votre contenu que dans le cadre de l’exploitation, de la promotion ou de l’amélioration de ses services. Si vous souhaitez mettre fin à cette licence, il vous suffit de supprimer votre compte. Le contenu que vous avez partagé sera cependant toujours visible pour les autres Utilisateurs : vous pouvez en demander la suppression, sous certaines réserves, auprès de la personne ressource, que vous pouvez contacter à admin@forum-americas.org.',
    { type: 'title', text: 'LIENS EXTERNES' },
    "Il se peut qu'en utilisant un hyperlien, l’Utilisateur quitte la Plateforme ou les Médias sociaux pour accéder à du contenu externe (ci-après les « Sites Internet Externes »). Le FEIA met à disposition des Utilisateurs, en plus de ses propres contenus, des liens vers des Sites Internet Externes uniquement pour raison de convivialité et à titre d’information pour l’Utilisateur. FEIA n’exerce aucun contrôle sur le contenu de ces Sites Internet Externes et n’assume aucune responsabilité quant à leur exactitude, précision, exhaustivité, authenticité, actualité ou adéquation. Toute responsabilité du FEIA est exclue, en particulier concernant les dommages directs ou indirects ou les conséquences liées à l’utilisation des contenus de ces Sites Internet Externes. FEIA n’exerce aucune influence sur les contenus des Sites Internet Externes auxquels renvoient les liens et s’affranchit de toute responsabilité quant à sa sélection rigoureuse, aux contenus des liens externes, en particulier à ceux qui ont été modifiés après la mise en place du lien. FEIA n’exige pas la transmission des informations, ne sélectionne pas les destinataires des informations transmises ni les informations elles-mêmes, et ne les modifie pas. En outre, aucune sauvegarde intermédiaire automatique à court terme de ces liens externes n’est effectuée par FEIA en vertu de la méthode de création et de consultation des liens en vigueur. Par conséquent, la responsabilité du contenu des Sites Internet Externes accessibles via des liens externes incombe exclusivement à leurs exploitants.",
    'En outre, FEIA ne peut nullement garantir l’absence de violation de quelconque loi, incluant l’absence de violation des droits d’auteur ou des marques de commerce ou des droits de tiers par ces Sites Internet Externes ou leur contenu, ni l’absence de virus ou d’autres composantes nuisibles.',
    'Aucune garantie n’est apportée quant à l’authenticité des documents disponibles sur Internet.',
    { type: 'title', text: 'MÉDIAS SOCIAUX ' },
    'Les conditions suivantes s’appliquent lorsque vous accédez à une page de Média social.',
    { type: 'subtitle', text: 'Déclaration générale' },
    'En utilisant les pages des Médias sociaux et en soumettant un commentaire, une photo, une vidéo ou tout autre élément, vous (ainsi que votre parent/tuteur légal si vous n’avez pas atteint l’âge de majorité dans la province ou le territoire où vous résidez) acceptez d’être lié par les Mentions légales du FEIA ainsi que les Conditions d’utilisation des fournisseurs tiers des Médias sociaux, et de vous y conformer. Vous consentez également à ce que FEIA copie, édite, publie, traduise et distribue votre commentaire, photo, vidéo ou autre élément publiés sur un Média social, et ce, sans contrepartie.',
    { type: 'subtitle', text: 'Conditions d’utilisation des Médias sociaux' },
    'Certains des commentaires et d’autres éléments affichés sur les pages de Médias sociaux pourraient ne pas représenter les opinions de FEIA. Ainsi, vous acceptez de ne pas publier ou soumettre de renseignements, de publications, de liens ou tout autre élément de toutes sortes sur les pages de Médias sociaux qui entrent dans l’une des catégories décrites ci-dessous, et ce, en regard de FEIA :',
    {
      type: 'list',
      items: [
        'Tout contenu diffamatoire, injurieux, contrefait, obscène, trompeur, illégal ;',
        'Tout contenu qui viole de toute autre façon les droits (y compris ceux liés à la protection des renseignements personnels) d’autrui ;',
        'Tout contenu comportant de la publicité ;',
        'Des documents promotionnels ou toute forme de sollicitations indésirables ;',
        'Tout contenu dont l’origine ou la source est falsifiée ;',
        'Tout renseignement financier ou personnel, à propos de vous ou d’une autre personne ; et',
        'Toute information que FEIA jugerait en contravention avec les Mentions légales.'
      ]
    },
    `Tout renseignement, message, lien ou élément que FEIA considère comme appartenant à l’une des catégories ci-dessus sera supprimé immédiatement, et ce sans responsabilité envers FEIA.`,
    `Par ailleurs, FEIA n’assume pas la responsabilité de filtrer les messages publiés sur les Médias sociaux.`,
    `FEIA décline toute responsabilité en ce qui a trait aux entités qui détiennent ou exploitent des Médias sociaux, notamment pour toute perte ou tout dommage direct ou indirect découlant de toute mesure ou décision prise par vous ou par une autre personne relativement à l’utilisation de sites web de Médias sociaux.`,
    { type: 'title', text: 'AVIS DE NON-RESPONSABILITÉ, EXCLUSION DE GARANTIE ET INDEMNISATION' },
    `FEIA ne garantit pas l’exactitude, l’intégralité, la qualité, la pertinence ni le contenu de l’information présente sur sa Plateforme. Cette information est fournie « telle quelle », sans garantie ni condition que ce soit, expresse ou implicite, y compris, entre autres, les conditions et garanties implicites à l’égard de la valeur commerciale et de la pertinence pour un but donné, sauf dans les territoires qui n’autorisent pas l’exclusion de garanties.`,
    `FEIA n’est pas responsable du contenu déposé sur la Plateforme par les Utilisateurs et des dommages pouvant en résulter. Chaque Utilisateur est responsable du contenu qu’il échange sur la Plateforme et ne peut utiliser la Plateforme à des fins contraires aux lois applicables au Québec et au Canada.`,
    `Sous réserve des lois applicables, FEIA ne pourra être tenue responsable de tous dommages directs, indirects, spéciaux, accessoires et consécutifs, ou de tout autre dommage quel qu’il soit, entre autres les pertes de revenus, de profits ou d’avantages économiques anticipés découlant de l’utilisation ou de l’impossibilité d’utiliser la Plateforme, les renseignements, documents et logiciels qui s’y trouvent, avec leur contenu, même si FEIA a été prévenu de la possibilité de tels dommages ou même si ces dommages peuvent être prévus de façon raisonnable.`,
    "À titre d'Utilisateur, vous indemniserez FEIA, et, le cas échéant, ses administrateurs, ses dirigeants, actionnaires, associés, employés, agents, consultants et autres intervenants de FEIA, et les dégagez de toute responsabilité pour l`ensemble des réclamations, responsabilités, pertes et frais (y compris les frais juridiques) attribuables à l’utilisation de la Plateforme et/ou à la violation de ces Conditions d’utilisation, que cette infraction soit de vous ou d’un quelconque tiers utilisateur de votre compte.",
    `Vous ne pouvez pas utiliser ou exporter l'information, ni aucune copie ou adaptation de l'information en contravention des lois ou des règlements applicables.`,

    { type: 'title', text: 'MODIFICATION DE CES CONDITIONS ' },
    'Nous mettons occasionnellement à jour ces Conditions d’utilisation. L’utilisation continue de la Plateforme constitue l’accord de l’Utilisateur quant à ces Conditions d’Utilisation ainsi qu’à ses mises à jour.',

    { type: 'title', text: 'INTERPRÉTATION' },
    'Les intitulés des articles qui précèdent sont insérés à titre de référence seulement et n’affecteront pas la construction ou l’interprétation des dispositions des présentes Conditions d’utilisation.',
    'Chaque fois que le contexte l’exige, tout mot écrit au singulier comprend aussi le pluriel et vice-versa ; tout mot écrit au genre masculin comprend aussi le genre féminin et vice-versa.',

    { type: 'title', text: 'PERSONNE RESSOURCE' },
    `Si vous avez des questions ou commentaires concernant tout contenu de la Plateforme, veuillez nous contacter à admin@forum-americas.org.`
  ]
}
