export default {
  title: 'Frequently asked questions',
  index: [
    {
      question: 'Is the IEFA Streaming Platform free?',
      answer: 'Yes, the IEFA will stream each of our six annual conferences for free throughout 2024.'
    },
    {
      question: 'What do I need to watch your live streams?',
      answer:
        'IEFA TV has been optimized to accommodate a number of browsers and devices which access our live video and on-demand content. <br/><br/> To view the live streams and on-demand video, you should have the latest version of your preferred web browser that supports HTML5 video. <br/><br/> Supported browsers include Microsoft Internet Explorer version 10 and up, Mozilla FireFox, Google Chrome, Opera, and Apple Safari, on desktop, as well as Safari, Chrome, and Dolphin, on Apple devices such as iPad and iPhone, and on most Android devices.'
    },
    {
      question: 'I can view videos on IEFA TV, but I cannot download them to my computer. Do you have a download URL?',
      answer: 'IEFA TV videos are encoded for streaming only and are currently not downloadable.'
    },
    {
      question: 'Is there any way to listen live, without the video?',
      answer: 'At this time, all of our streams contain video and audio.'
    },
    {
      question: 'Are all sessions broadcasted in multiple languages?',
      answer:
        'The Conference of Montreal and Conference of Paris are broadcast in both French and English. The IEFA Latam Forum is broadcast in English and Spanish. <br/><br/> At this stage, the World Strategic Forum, Toronto Global Forum and Phoenix Global Forum are only broadcast in English.'
    }
  ]
}
