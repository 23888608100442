<template>
  <section>
    <ConferenceHeader :conference="conference" />
    <ConferenceRooms v-if="isLive && tracks" :event="nextEvent" :tracks="tracks" />
    <ConferenceLive v-if="isLive" v-bind="{ conference, tracks }" :event="nextEvent" />
    <ConferenceNext v-else-if="nextEvent" v-bind="{ conference, nextEvent }" />
    <ConferenceIndex :conference="conference" />
    <ConferenceAbout :conference="conference" />
  </section>
</template>

<script>
import moment from 'moment'
import _groupBy from 'lodash.groupby'
import * as partials from './partials'

export default {
  name: 'Conference',
  components: { ...partials },
  props: {
    slug: { type: String, required: true }
  },
  data() {
    return {
      liveTime: moment()
    }
  },
  computed: {
    conference() {
      return this.$t(`conferences.index.${this.slug}`)
    },
    nextEvent() {
      return this.$api.nextEventBySlug[this.conference.slug]
    },
    isLive() {
      return !!this.$api.liveEvent && this.$api.liveEvent.slug === this.conference.slug
    },
    tracks() {
      if (!this.isLive || !this.nextEvent || !this.nextEvent.sessions.some(({ room }) => room)) return null
      return _groupBy(this.nextEvent.sessions, 'room')
    }
  },
  watch: {
    tracks: {
      immediate: true,
      handler() {
        // console.log(this.$api.conferences[this.conference.slug])
        // console.log('nextEvent', this.nextEvent)
        // console.log('tracks', this.tracks)
      }
    }
  },
  created() {
    this.updateLiveTime()
  },
  methods: {
    updateLiveTime() {
      this.liveTime = moment()
      setTimeout(this.updateLiveTime, 1000)
    }
  }
}
</script>
