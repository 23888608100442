<template>
  <RouterLink v-bind="routerLinkProps">
    <slot />
  </RouterLink>
</template>

<script>
import getLocalizedRoute from '@/helpers/getLocalizedRoute'

export default {
  name: 'LocaleLink',
  props: {
    to: { type: Object, required: true },
    tag: { type: String, default: null },
    exact: { type: Boolean, default: false }
  },
  computed: {
    routerLinkProps() {
      const to = getLocalizedRoute(this.$i18n.locale, this.to)
      return this.tag ? { to, exact: this.exact, tag: this.tag } : { to, exact: this.exact }
    }
  }
}
</script>
