<template>
  <article v-if="!nextEvent.hidden" class="layout-section -split -bg-white">
    <section class="layout-section__content">
      <h6 class="layout-section__toptitle -accent">{{ $t('common.nextEvent') }}</h6>
      <h2 class="layout-section__title">{{ nextEvent.title }}</h2>
      <p>{{ nextEvent.description }}</p>

      <div class="layout-section__cta">
        <a :href="conference.website" target="_blank" rel="noopener" class="button -darker">{{
          $t('common.cta.learnMore')
        }}</a>

        <button class="button -disabled">{{ $t('common.cta.liveOn') }} {{ nextEvent.displayDate }}</button>
      </div>
    </section>

    <!-- <figure class="layout-section__figure -light -reverse -video">
      <div class="video__play">
        <svg-icon id="video-play" />
      </div> -->
    <!-- <iframe src="https://www.youtube.com/embed/p_3XvuOoPwc" title="Phoenix Global Forum Teaser" /> -->
    <!-- </figure> -->
  </article>
</template>

<script>
export default {
  name: 'ConferenceNext',
  props: {
    conference: { type: Object, required: true },
    nextEvent: { type: Object, default: null }
  }
}
</script>
