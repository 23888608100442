import { v4 as uuid } from 'uuid'

const classNames = {
  base: 'transition-appear',
  active: '-appear-active',
  from: '-appear-from',
  to: '-appear-to',
  directions: {
    up: '-up',
    right: '-right',
    down: '-down',
    left: '-left'
  }
}

const observers = {}

const createObserverHandler = (key, customHandler, scrollDelay) => entries => {
  entries.forEach(({ target, isIntersecting }) => {
    if (!isIntersecting) return
    observers[key].unobserve(target)
    if (customHandler) return customHandler(target)

    if (scrollDelay) {
      const [$scroll, topDelay, delay = 0] = scrollDelay
      target.style.setProperty('--transition-delay', `${$scroll.position < 150 ? topDelay : delay}ms`)
    }

    target.classList.add(classNames.active)
    setTimeout(() => target.classList.replace(classNames.from, classNames.to), 100)
  })
}

const getObserver = ({ offset = '-5%', threshold = 0.5, customHandler, scrollDelay }) => {
  const safeOffset = typeof offset === 'number' ? `${offset}px` : offset
  const rootMargin = `0px 0px ${safeOffset}`
  const observerOptions = { rootMargin, threshold }
  const key = customHandler || scrollDelay ? uuid() : JSON.stringify(observerOptions)
  if (observers[key]) return observers[key]
  const observer = new IntersectionObserver(createObserverHandler(key, customHandler, scrollDelay), observerOptions)
  observers[key] = observer
  return observer
}

getObserver({})

export default Vue => {
  Vue.directive('appear', {
    bind(el, { arg = 'up', value = {} }) {
      if (value.handler) return

      const { delay, distance } = value
      el.classList.add(classNames.base, classNames.from, classNames.directions[arg])
      if (delay && !Array.isArray(delay)) el.style.setProperty('--transition-delay', `${delay}ms`)

      if (distance) {
        const translateBy = arg === 'right' || arg === 'down' ? `-${distance}` : distance
        el.style.setProperty('--translate-by', translateBy)
      }
    },
    inserted(el, { arg = 'up', value = {} }) {
      const { offsetTop, offsetBottom, threshold, delay, handler: customHandler } = value
      const scrollDelay = delay && Array.isArray(delay) ? delay : null
      const observer = getObserver({ offsetTop, offsetBottom, threshold, customHandler, scrollDelay })
      observer.observe(el)
      if (value.handler) return

      el.addEventListener(
        'transitionend',
        () => {
          el.classList.remove(classNames.base, classNames.active, classNames.to, classNames.directions[arg])
          el.style.removeProperty('--transition-delay')
          el.style.removeProperty('--translate-by')
        },
        { once: true }
      )
    }
  })
}
