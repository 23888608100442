<template>
  <menu class="navigation-breadcrumb page-header__menu">
    <LocaleLink
      v-if="hash"
      v-appear:right="{ delay: 525 }"
      :to="{ name: 'home', hash }"
      exact
      class="navigation-breadcrumb__item"
    >
      <p>{{ back }}</p>
    </LocaleLink>

    <div
      v-for="({ link, label }, index) in pages"
      :key="link"
      v-appear:right="{ delay: 550 + index * 25 }"
      class="navigation-breadcrumb__item"
      :class="{ '-active': index === pages.length - 1 }"
    >
      <span class="navigation-breadcrumb__separator">/</span>
      <LocaleLink v-if="link" :to="{ name: link }">{{ label }}</LocaleLink>
      <p v-else>{{ label }}</p>
    </div>
  </menu>
</template>

<script>
export default {
  name: 'NavigationBreadcrumb',
  props: {
    back: { type: String, default: '' },
    hash: { type: String, default: null },
    page: { type: [String, Array], default: '' }
  },
  computed: {
    pages() {
      return Array.isArray(this.page) ? this.page : [{ label: this.page }]
    }
  }
}
</script>
