<template>
  <article class="layout-section -split">
    <figure class="layout-section__figure" :style="{ '--image': `url('/images/about.png')` }" />
    <section class="layout-section__content">
      <h3 class="layout-section__toptitle">{{ $t('home.about.toptitle') }}</h3>
      <h2 class="layout-section__title">{{ $t('home.about.title') }}</h2>
      <p>{{ $t('home.about.description') }}</p>
      <a href="https://forum-ameriques.org/" target="_blank" class="layout-section__cta button">{{
        $t('common.cta.learnMore')
      }}</a>
    </section>
  </article>
</template>

<script>
export default {
  name: 'HomeAbout'
}
</script>
