<template>
  <section>
    <FaqHeader />
    <FaqIndex />
  </section>
</template>

<script>
import * as partials from './partials'

export default {
  name: 'Faq',
  components: { ...partials }
}
</script>
