<template>
  <article>
    <section class="page-header -smaller">
      <h1 class="page-header__title">{{ $t('terms.title') }}</h1>
    </section>

    <section class="layout-section -no-top">
      <div class="article-content">
        <template v-if="$t('terms.wp') === 'true'">
          <div v-html="$t('terms.content')" />
        </template>

        <template v-else>
          <template v-for="(entry, index) in $t('terms.content_static')">
            <p v-if="typeof entry === 'string'" :key="entry" v-html="entry" />
            <h2 v-else-if="entry.type === 'title'" :key="entry.text">{{ entry.text }}</h2>
            <h3 v-else-if="entry.type === 'subtitle'" :key="entry.text">{{ entry.text }}</h3>
            <ul v-else-if="entry.type === 'list'" :key="index">
              <li v-for="item in entry.items" :key="item" v-html="item" />
            </ul>
          </template>
        </template>
      </div>

      <p class="article-content__note">{{ $t('terms.update') }}</p>
    </section>
  </article>
</template>

<script>
export default {
  name: 'Terms',
  metaInfo() {
    return this.$t('terms.meta')
  }
}
</script>
