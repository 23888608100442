<template>
  <section class="schedule-week">
    <p>Coming Soonw</p>
  </section>
</template>

<script>
export default {
  name: 'ScheduleWeek'
}
</script>
