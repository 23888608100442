<template>
  <section class="modal">
    <article class="modal__box">
      <header class="modal__header">
        <h2 class="modal__title">{{ title }}</h2>
        <p>{{ subtitle }}</p>
      </header>

      <section class="modal__content">
        <slot />
      </section>

      <button class="button -big">{{ button }}</button>
    </article>
  </section>
</template>

<script>
export default {
  name: 'ActionModal',
  props: {
    title: { type: String, required: true },
    subtitle: { type: String },
    button: { type: String, required: true }
  }
}
</script>
