export default {
  title: 'Conferencias',
  description: 'Vea las sesiones de cada conferencia de la IEFA',
  next: 'El Phoenix Global Forum examinará la cooperación internacional y la innovación estratégica necesarias para abordar los desafíos globales emergentes. Los tomadores de decisiones y líderes de los mundos de los negocios, el gobierno y la academia se reunirán para discutir estos desafíos y las oportunidades subsiguientes que pueden garantizar un crecimiento (...)',
  onDemand: {
    title: 'Vídeo a la carta',
    description: 'Vea las retransmisiones de los eventos anteriores.',
    noStreams: 'No streams available'
  },
  index: {
    montreal: {
      slug: 'montreal',
      title: 'Conferencia de Montreal',
      year: '1994',
      description:
        'La Conferencia de Montreal fue lanzada por le IEFA en 1994 y reúne a responsables de todos los ámbitos de la sociedad para abordar los grandes temas del momento.',
      date: '10-12 de junio 2024',
      website: 'https://www.laconferencedemontreal.com',
      learnMoreLink: 'https://forum-ameriques.org/conferences/montreal',
      nextEvent: {
        title: 'LIDERANDO EN UN MUNDO EN ACELERACIÓN',
        description:
          'La Era de la Aceleración se refiere al progreso exponencial del procesamiento informático, las tecnologías de la comunicación, los mercados globalizados y la generación energética que caracteriza nuestro tiempo. Estos cambios, que han ocurrido en menos de dos décadas, han transformado profundamente la forma en que gobernamos, trabajamos, consumimos e interactuamos entre nosotros. Además de los trastornos causados por estas transformaciones en la conducción de los asuntos globales, el avance vertiginoso del cambio climático y los imperativos establecidos en el Acuerdo de París han llevado a un cambio radical en las operaciones industriales y las inversiones globales.',
        website: 'https://www.laconferencedemontreal.com',
        learnMoreLink: 'https://forum-americas.org/conferences/montreal'
      }
    },
    phoenix: {
      slug: 'phoenix',
      title: 'Phoenix Global Forum',
      year: '2024',
      description:
        'El Phoenix Global Forum está organizado por le IEFA en asociación con Phoenix Sister Cities. Phoenix, la gran ciudad de más rápido crecimiento de Estados Unidos, se está convirtiendo rápidamente en un centro para los sectores de las biociencias, la tecnología, la sostenibilidad y la defensa, lo que está atrayendo importantes inversiones externas a la región y creando un floreciente ecosistema empresarial.',
      date: '2 de mayo 2024',
      website: 'https://www.phoenixglobalforum.com/',
      learnMoreLink: 'https://forum-ameriques.org/conferences/phoenix',
      nextEvent: {
        title: 'Forging the Future',
        description:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat (…)'
      }
    },
    toronto: {
      slug: 'toronto',
      title: 'Toronto Global Forum',
      year: '2023',
      description:
        'El Toronto Global Forum está organizado por la IEFA en asociación con Integra Capital. El primer evento se lanzó en 2006 y reúne a líderes internacionales del mundo empresarial, gubernamental y de la sociedad civil para debatir los principales retos y oportunidades a los que se enfrenta actualmente la economía mundial.',
      date: '16-18 de octubre 2024',
      website: 'https://www.torontoglobalforum.com/',
      learnMoreLink: 'https://forum-ameriques.org/conferences/toronto',
      nextEvent: {
        title: 'Scaling for a Sustainable Future',
        description:
          'El Toronto Global Forum actúa como una plataforma donde las organizaciones e instituciones pueden conectarse con un enfoque en posibles asociaciones, oportunidades de inversión y colaboración futura. A medida que los países y las industrias buscan formas de mejorar la resiliencia ambiental y económica y fomentar el desarrollo social, la conferencia tiene como objetivo explorar estos temas a través de una lente internacional.',
        website: 'https://www.torontoglobalforum.com/',
        learnMoreLink: 'https://forum-ameriques.org/conferences/toronto'
      }
    },
    paris: {
      slug: 'paris',
      title: 'Conferencia de París',
      year: '2023',
      description:
        'Lanzada en 2017, la Conferencia de París reúne a responsables de la toma de decisiones de empresas, gobiernos y sociedad civil para promover el intercambio de ideas y perspectivas en torno a los grandes temas de la actualidad. El evento está organizado por el IEFA e Integra Capital.',
      date: '16-17 de diciembre 2024',
      website: 'https://www.conferencedeparis.com/',
      learnMoreLink: 'https://forum-ameriques.org/conferences/paris'
    },
    miami: {
      slug: 'miami',
      title: 'World Strategic Forum',
      year: '2023',
      description:
        'El World Strategic Forum está organizado por FEIA en asociación con Integra Capital y el Adam Smith Center for Economic Freedom de la Florida International University  (FIU). El WSF se creó en 2011 y se celebra anualmente en Miami, recibiendo a participantes de todo el mundo. El WSF reúne a líderes de todas las esferas de la sociedad para debatir las cuestiones más apremiantes de nuestro tiempo.',
      date: '10-11 de diciembre 2024',
      website: 'https://www.worldstrategicforum.com/',
      learnMoreLink: 'https://forum-ameriques.org/conferences/miami'
    },
    'buenos-aires': {
      slug: 'buenos-aires',
      title: 'IEFA Latam Forum',
      year: '2023',
      description:
        'El IEFA Latam Forum celebró su primera edición en 2022. Se trata de una conferencia internacional anual centrada en el papel y las oportunidades de América Latina en la economía global actual.',
      date: '',
      website: 'https://www.latamforum.com/',
      learnMoreLink: 'https://forum-ameriques.org/conferences/buenos-aires'
    }
  }
}
