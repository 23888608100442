<template>
  <LocaleLink :to="link" class="grid__card">
    <figure class="grid__figure -card" :style="{ '--image': `url('${event.thumbnail}')` }" />
    <section class="grid__content">
      <h6 class="grid__toptitle">{{ event.edition }}</h6>
      <h3 class="grid__title">{{ event.title }}</h3>

      <div class="grid__info">
        <svg-icon id="video-list" />
        <p>{{ event.sessions.length }}</p>
      </div>
    </section>
  </LocaleLink>
</template>

<script>
export default {
  name: 'ConferenceIndexItem',
  props: {
    event: { type: Object, required: true }
  },
  computed: {
    link() {
      return { name: 'streaming', params: { slug: this.$route.params.slug, year: this.event.year } }
    }
  }
}
</script>
