<template>
  <article v-if="isSectionVisible" id="live-viewer" class="layout-viewer -bg-white">
    <section class="layout-viewer__container">
      <figure class="layout-viewer__video video -stream">
        <iframe
          :src="embedUrl"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          class="video__player"
          allowfullscreen
        />

        <div class="video__live">
          <span class="video__live-icon" />
          <p>{{ $t('common.live') }}</p>
        </div>
      </figure>

      <nav class="layout-viewer__languages">
        <RouterLink
          v-for="{ lang, query } in languages"
          :key="lang"
          :to="{ query }"
          class="layout-viewer__lang-link"
          @click.native="$scroll.toTarget('#live-viewer', 70)"
          >{{ languageLabels[lang] }}</RouterLink
        >
      </nav>

      <article class="layout-viewer__content">
        <h2 class="layout-section__title">{{ currentSession.title }}</h2>
        <p>{{ currentSession.description }}</p>
      </article>
    </section>

    <ConferenceLivePanel v-bind="{ sessions, currentSessionIndex }" />
  </article>
</template>

<script>
import moment from 'moment'
import _sortBy from 'lodash.sortby'
import _omit from 'lodash.omit'
import ConferenceLivePanel from './ConferenceLivePanel.vue'

export default {
  name: 'ConferenceLive',
  components: { ConferenceLivePanel },
  props: {
    conference: { type: Object, required: true },
    event: { type: Object, required: true },
    tracks: { type: Object, default: null }
  },
  computed: {
    languageLabels() {
      return {
        fr: 'Français',
        en: 'English',
        es: 'Español',
        floor: this.$t('common.floor')
      }
    },
    isSectionVisible() {
      return (
        (!this.tracks || !!this.currentTrack) &&
        !!this.embedUrl &&
        this.$api.liveTime.isSameOrAfter(this.firstSessionStartTime) &&
        this.$api.liveTime.isSameOrBefore(moment(this.lastSessionEndTime).add(30, 'minutes'))
      )
    },
    sessions() {
      const filterCurrentDay = ({ startTime }) => moment(startTime).isSame(this.$api.liveTime, 'day')
      if (!this.tracks) return this.event.sessions.filter(filterCurrentDay)
      return !this.tracks || !!this.currentTrack ? this.tracks[this.currentTrack].filter(filterCurrentDay) : []
    },
    currentSession() {
      const now = this.$api.liveTime

      return _sortBy(this.sessions, 'startTime')
        .reverse()
        .find(({ startTime }) => now.isAfter(startTime))
    },
    currentSessionIndex() {
      return this.sessions.indexOf(this.currentSession)
    },
    currentTrack() {
      return this.$route.query.track || null
    },
    firstSessionStartTime() {
      return this.sessions
        .reduce(
          (firstSessionStartTime, { startTime }) => moment.min([firstSessionStartTime, moment(startTime)]),
          moment().add(1, 'year')
        )
        .toISOString()
    },
    lastSessionEndTime() {
      return this.sessions
        .reduce((lastSessionEndTime, { endTime }) => moment.max([lastSessionEndTime, moment(endTime)]), moment(0))
        .toISOString()
    },
    languages() {
      return Object.entries(this.currentSession.eventId)
        .filter(([, value]) => value)
        .map(([lang]) => ({
          lang,
          query: lang === 'floor' ? _omit(this.$route.query, 'lang') : { ...this.$route.query, lang }
        }))
    },
    embedUrl() {
      if (!this.currentSession) return null
      const currentLanguage = this.$route.query.lang || 'floor'
      const fallbackEventId = Object.values(this.currentSession.eventId).find(Boolean)
      const eventId = this.currentSession.eventId[currentLanguage] || fallbackEventId
      return `https://vimeo.com/event/${eventId}/embed`
    }
  },
  watch: {
    sessions: {
      immediate: true,
      handler() {
        // console.log('sessions', this.sessions)
        // console.log('currentSessionIndex', this.currentSessionIndex)
        // console.log('lastSessionEndTime', this.lastSessionEndTime)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.currentTrack) this.$scroll.toTarget('#live-viewer', 70)
    })
  }
}
</script>
