<template>
  <ActionModal
    title="Message envoyé avec succès !"
    subtitle="Sociis diam et aliqua? Nonummy sint consequat sunt, elit pretium libero vestibulum! Do nesciunt nisi."
    button="Terminé"
  />
</template>

<script>
export default {
  name: 'ActionModalFreebies'
}
</script>
